import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ScrollService {
	private anchorId: string | null = null;

	setAnchorId(id: string): void {
		this.anchorId = id;
	}

	getAnchorId(): string | null {
		return this.anchorId;
	}

	clearAnchorId(): void {
		this.anchorId = null;
	}
}
