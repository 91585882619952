import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/* Services */
import {
	LanguageService,
	ShareService,
	SeoService,
	AppService,
	AboutService
} from "@services";

/* Plugins */
import {
	TranslateService,
	LangChangeEvent
} from "@ngx-translate/core";

@Component({
	selector: "about",
	templateUrl: "./about.component.html",
	styleUrls: ["./about.component.scss"],
	encapsulation: ViewEncapsulation.None
})

export class AboutComponent implements OnInit, OnDestroy {
	currentLang: string;
	unsubscribeAll = new Subject();
	isDataLoading = false;
	title: string;
	text: string;
	awardsData: Array<object>;
	historyData: Array<object>;

	constructor(
		private languageService: LanguageService,
		private translateService: TranslateService,
		private shareService: ShareService,
		private seoService: SeoService,
		private appService: AppService,
		private aboutService: AboutService
	) {
		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				this.currentLang = event.lang;
			});
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(true);

		const lang = this.languageService.currentLang;

		if (this.currentLang === undefined && lang) {
			this.currentLang = lang;
		}

		this.getAboutData();
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}

	getAboutData() {
		if (this.isDataLoading) {
			return;
		}

		this.isDataLoading = true;

		this.aboutService
			.getAboutData()
			.subscribe(
				(response) => {
					if (response == null || typeof response !== "object") {
						return;
					}

					this.title = response[this.currentLang].title;
					this.text = response[this.currentLang].text;
					this.awardsData = response[this.currentLang].awards;
					this.historyData = response[this.currentLang].histories;

					this.appService.togglePreloaderSubject.next(false);

					this.isDataLoading = false;
				},
				(err) => {
					this.appService.togglePreloaderSubject.next(false);

					this.isDataLoading = false;

					console.log(err);
				}
			);
	}
}


