import { Component, OnInit } from '@angular/core';
import {AppService, EducationService} from "@services";
import {environment} from "@environments";
import Swiper from 'swiper';

@Component({
  selector: 'app-strategists-course',
  templateUrl: './strategists-course.component.html'
})
export class StrategistsCourseComponent implements OnInit {

	protected readonly environment = environment;
	curatorsList;
	internshipsDirections;
	idCourse = 2;

	constructor(private api: EducationService,
				private appService: AppService) {
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(true);
		this.api.curators(this.idCourse).subscribe((response) => {
			this.curatorsList = response;
			if (this.curatorsList){
				setTimeout(() => {
					this.initSlider()
					this.appService.togglePreloaderSubject.next(false);
				})
			}
		});

		this.api.internshipsDirections(this.idCourse).subscribe((response) => {
			this.internshipsDirections = response;
		});
	}

	initSlider() {
		new Swiper(".swiper-container", {
			loop: false,
			speed:800,
			slidesPerView: 4,
			centeredSlides : false,
			shadow: false,
			grabCursor: true,
			parallax: true
		});
	}

}
