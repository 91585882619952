import { Component, HostListener, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/*Services */
import { AppService, ShareService } from "@services";

@Component({
	selector: "share-block",
	templateUrl: "./share-block.component.html",
	styleUrls: ["share-block.component.scss"],
	encapsulation: ViewEncapsulation.None
})

export class ShareBlockComponent implements OnDestroy {
	showShare = false;
	showBlock = false;
	fixedBlock = false;
	unsubscribeAll = new Subject();
	share;
	constructor(
		private appService: AppService,
		private shareService: ShareService
	) {
		this.appService.onScrollSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((scroll) => {
				this.onWindowScroll();
			});
		this.appService.togglePreloaderSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				this.onWindowScroll();
				setTimeout(() => {
					this.onWindowScroll();
				});
			});
	}


	toggleShareShow() {
		this.showShare = !this.showShare;
	}

	onWindowScroll() {
		const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		if ((verticalOffset + window.innerHeight) > (document.body.scrollHeight - 100)) {
			this.fixedBlock = true;
		} else {
			this.fixedBlock = false;
		}
		if (verticalOffset > 100) {
			this.showBlock = true;
		} else {
			this.showBlock = false;
		}
	}

	scrollTop() {
		this.scrollToTop();
	}

	scrollToTop() {
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}

}

