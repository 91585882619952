import { Component, ViewEncapsulation, ViewChild, ElementRef } from "@angular/core";
import Player from "@vimeo/player";

@Component({
	selector: "video-player",
	templateUrl: "./video-player.component.html",
	styleUrls: ["video-player.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class VideoPlayerComponent {

	youtubeVideoUrl: string;
	vimeoVideoUrl: string;
	playerYoutube: YT.Player;
	playerVimeo: Player;
	elementRef;

	@ViewChild("playerVimeo") set controlElRef(elementRef: ElementRef) {
		this.playerVimeo = elementRef;
	}

	savePlayer(player) {
		this.playerYoutube = player;
		this.playerYoutube.playVideo();
	}

	play(id, type) {
		this.stopVideo();

		if (type === "youtube") {
			this.youtubeVideoUrl = id;
		} else if (type === "vimeo") {
			this.vimeoVideoUrl = id;
			setTimeout(() => {
				this.playerVimeo = new Player(this.playerVimeo.nativeElement, {
					id: this.vimeoVideoUrl
				});
				this.playerVimeo.play();
			});
		}
	}

	stopVideo() {
		if (this.playerYoutube) {
			this.playerYoutube.stopVideo();
			this.playerYoutube = null;
		}
		if (this.playerVimeo) {
			this.playerVimeo = null;
		}
		this.youtubeVideoUrl = null;
		this.vimeoVideoUrl = null;
	}
}
