import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

/* Components */
import { AppComponent } from "./app.component";
import {
	HomeComponent,
	AllWorksComponent,
	DetailWorkComponent,
	ClientsComponent,
	AllPeopleComponent,
	DetailPeopleComponent,
	AboutComponent,
	ContactComponent,
	SearchComponent,
	PageNotFoundComponent,
	EducationComponent
} from "./view/pages";
import {
	HeaderComponent,
	SearchHeaderComponent,
	MenuHeaderComponent,
	VideoPlayerComponent,
	FooterComponent,
	ShareBlockComponent,
	GridWorksComponent,
	TileItemComponent,
	WorksSliderComponent,
	FilterWorksListComponent,
	FilterClientsListComponent,
	TwoColumnTileComponent,
	GridPeopleComponent,
	ImageSliderComponent,
	SearchBlockComponent,
	PreloaderComponent,
	CourseCardComponent
} from "./view/components";

/* Modules */
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

/* Plugins */
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { YoutubePlayerModule } from "ngx-youtube-player";
import { Ng2CompleterModule } from "ng2-completer";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { CreativeCourseComponent } from './view/pages/education/course-pages/creative-course/creative-course.component';

import {ModalComponent} from "./core/directives";
import { AboutCourseComponent } from './view/dialogs/about-course/about-course.component';
import { SuccessfulSendingComponent } from './view/dialogs/successful-sending/successful-sending.component';
import { ViewVideoComponent } from './view/dialogs/view-video/view-video.component';
import { StrategistsCourseComponent } from './view/pages/education/course-pages/strategists-course/strategists-course.component';
import { AccountsCourseComponent } from './view/pages/education/course-pages/accounts-course/accounts-course.component';
import { SuccessfulInternshipComponent } from './view/dialogs/successful-internship/successful-internship.component';
import { FormNewCourseComponent } from './view/components/form-new-course/form-new-course.component';
import { CareerTestComponent } from './view/dialogs/career-test/career-test.component';
import { TakeTestComponent } from './view/dialogs/take-test/take-test.component';
import {NgxMaskModule} from "ngx-mask";
import { CookiePopupComponent } from './view/components/cookie-popup/cookie-popup.component';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		HeaderComponent,
		AllWorksComponent,
		DetailWorkComponent,
		ClientsComponent,
		AllPeopleComponent,
		DetailPeopleComponent,
		AboutComponent,
		ContactComponent,
		SearchComponent,
		SearchHeaderComponent,
		MenuHeaderComponent,
		VideoPlayerComponent,
		FooterComponent,
		ShareBlockComponent,
		GridWorksComponent,
		TileItemComponent,
		PageNotFoundComponent,
		WorksSliderComponent,
		FilterWorksListComponent,
		FilterClientsListComponent,
		TwoColumnTileComponent,
		GridPeopleComponent,
		ImageSliderComponent,
		SearchBlockComponent,
		PreloaderComponent,
		EducationComponent,
		CourseCardComponent,
		CreativeCourseComponent,
		ModalComponent,
		AboutCourseComponent,
		SuccessfulSendingComponent,
		ViewVideoComponent,
		StrategistsCourseComponent,
		AccountsCourseComponent,
		SuccessfulInternshipComponent,
		FormNewCourseComponent,
		CareerTestComponent,
		TakeTestComponent,
		CookiePopupComponent
	],

	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		CoreModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		YoutubePlayerModule,
		FormsModule,
		Ng2CompleterModule,
		SlickCarouselModule,
		ReactiveFormsModule,
		NgxMaskModule.forRoot()
	],

	providers: [

	],
	
	bootstrap: [AppComponent]
})

export class AppModule { }
