import { Component, Input } from "@angular/core";
import { environment } from "@environments";

/*Services */
import { WorksService } from "@services";


@Component({
	selector: "image-slider",
	templateUrl: "./image-slider.component.html"
})
export class ImageSliderComponent {

	@Input("imagesList") imagesList;
	baseUrl: string = environment.url;

	slideConfig = { "slidesToShow": 1, "slidesToScroll": 1 };

	constructor(
		private worksService: WorksService,
	) { }

	playVideo(id, type) {
		/*Проигрываем видео по клику */
		this.worksService.playVideoSubject.next({ id: id, type: type });
	}
}
