import { Component, Input } from "@angular/core";
import { environment } from "@environments";

/*Services */
import { WorksService } from "@services";

@Component({
	selector: "works-slider",
	templateUrl: "./works-slider.component.html"
})

export class WorksSliderComponent {
	@Input("mastHeads") mastHeads;
	@Input("srcUrl") srcUrl;
	slideConfig = {
		"slidesToShow": 1,
		"slidesToScroll": 1,
		"autoplay": true,
		"autoplaySpeed": 3000
	};

	baseUrl: string = environment.url;

	constructor(
		private worksService: WorksService,
	) { }

	playVideo(id, type) {
		/*Проигрываем видео по клику */
		this.worksService.playVideoSubject.next({ id: id, type: type });
	}
}
