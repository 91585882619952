import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/*Services */
import { LanguageService, ShareService, SeoService, AppService } from "@services";

/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

/*Static */
import { contactInfo } from "./contact.static";

declare var ymaps: any;

@Component({
	selector: "contact",
	templateUrl: "./contact.component.html",
	styleUrls: ["./contact.component.scss"],
	encapsulation: ViewEncapsulation.None
})

export class ContactComponent implements OnInit, OnDestroy {
	@ViewChild("yamaps") el: ElementRef;
	map;
	contactInfo = contactInfo;
	currentLang: string;
	unsubscribeAll = new Subject();
	options: any = {
		center: [55.723686, 37.652114],
		zoom: 17,
		controls: ["zoomControl"]
	};

	constructor(
		private translateService: TranslateService,
		private languageService: LanguageService,
		private shareService: ShareService,
		private seoService: SeoService,
		private appService: AppService
	) {
		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				this.currentLang = event.lang;
			});
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(false);

		const lang = this.languageService.currentLang;

		if (this.currentLang === undefined && lang) {
			this.currentLang = lang;
		}
		this.initMap();
		this.seoService.initMeta();
	}

	initMap() {
		ymaps.ready().done(() => {
			this.map = new ymaps.Map(this.el.nativeElement, this.options);
			// Создаем метку с помощью вспомогательного класса.
			const myPlacemark = new ymaps.Placemark([55.723686, 37.652114], {
				// Свойства.
				hintContent: "BBDO Moscow"
			}, {
					// Опции.
					iconLayout: "default#image",
					// Своё изображение иконки метки.
					iconImageHref: "/assets/images/map.png",
					// Размеры метки.
					iconImageSize: [20, 30],
					// Смещение левого верхнего угла иконки относительно
					// её "ножки" (точки привязки).
					iconImageOffset: [0, -25]
				});
			this.map.behaviors.disable("scrollZoom");
			if (document.body.classList.contains("touch-device")) {
				this.map.behaviors.disable("drag");
			}

			this.map.geoObjects.add(myPlacemark);
		});
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}
