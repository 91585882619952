import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class VideoService {
	private dataStream = new Subject<any>();
	dataStream$ = this.dataStream.asObservable();

	sendData(data: any) {
		this.dataStream.next(data);
	}
}
