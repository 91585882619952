import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class LanguageService {
	public SetLanguageSubject: Subject<any> = new Subject<any>();
	public SetLanguageObservable = this.SetLanguageSubject.asObservable();
	currentLang;
}

