import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";

/*Services */
import { ApiService } from "./api.service";

@Injectable()

export class PeopleService {
	public clickLoadWorksPersonSubject: Subject<any> = new Subject<any>();
	public clickLoadWorksPersonObservable = this.clickLoadWorksPersonSubject.asObservable();

	constructor(
		private apiService: ApiService
	) { }

	getPersonList(): Observable<any> {
		return this.apiService.get(`/people/list`).pipe(map(data => data));
	}

	getPersonInfo(id): Observable<any> {
		return this.apiService.get(`/people/${id}`).pipe(map(data => data));
	}

	getPersonWorks(id: any, limit?: number, offset?: number): Observable<any> {
		let params = new HttpParams();

		if (limit) {
			params = params.set("limit", limit.toString());
		}
		if (offset) {
			params = params.set("offset", offset.toString());
		}

		return this.apiService.get(`/people/${id}/works`, params)
			.pipe(map(data => data));
	}
}
