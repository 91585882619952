import { Component, ViewEncapsulation, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/*Services */
import { AppService } from "@services";

@Component({
	selector: "preloader",
	templateUrl: "./preloader.component.html",
	styleUrls: ["./preloader.component.scss"],
	encapsulation: ViewEncapsulation.None
})

export class PreloaderComponent implements OnDestroy {
	unsubscribeAll = new Subject();
	ifShowPreloader = true;
	timerShowPreloader;

	constructor(
		private appService: AppService
	) {
		this.appService.togglePreloaderSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				if (this.timerShowPreloader) {
					clearInterval(this.timerShowPreloader);
				}
				if (value) {
					/*Делаем задержку в 500мс, перед тем как показать, если страница загрузится быстрее - не показываем */
					this.timerShowPreloader = setTimeout(() => {
						this.ifShowPreloader = true;
					}, 500);
				} else {
					this.ifShowPreloader = false;
				}
			});
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}
