import { Injectable, OnInit } from "@angular/core";
declare var Ya: any;

@Injectable()
export class ShareService {
	share;

	initShare(lang?) {
		if (this.share !== undefined) {
			return;
		}
		if (!lang) {
			lang = "ru";
		}
		this.share = Ya.share2("my-share", {
			theme: {
				services: "vkontakte,facebook,twitter,whatsapp,telegram",
				lang: lang,
				bare: true
			}
		});
	}
	updateShare(title, description, url, image) {
		this.share.updateContent({
			title: title,
			description: description,
			url: url,
			image: image
		});
	}

}


