export * from "./header/header.component";
export * from "./search-header/search-header.component";
export * from "./menu-header/menu-header.component";
export * from "./video-player/video-player.component";
export * from "./footer/footer.component";
export * from "./share-block/share-block.component";
export * from "./grid-works/grid-works.component";
export * from "./tile-item/tile-item.component";
export * from "./works-slider/works-slider.component";
export * from "./filter-works-list/filter-works-list.component";
export * from "./filter-clients-list/filter-clients-list.component";
export * from "./two-column-tile/two-column-tile.component";
export * from "./grid-people/grid-people.component";
export * from "./image-slider/image-slider.component";
export * from "./search-block/search-block.component";
export * from "./preloader/preloader.component";
export * from "./course-card/course-card.component";
export * from "./form-new-course/form-new-course.component";
