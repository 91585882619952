import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
/*Config */
import { CONFIG } from "@config";
/*Services */
import { WorksService, LanguageService, ShareService, SeoService, AppService } from "@services";
/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
	selector: "home",
	templateUrl: "./home.component.html"
})
export class HomeComponent implements OnInit, OnDestroy {

	@ViewChild("gridWorks") gridWorks;

	currentLang: string;
	unsubscribeAll = new Subject();

	ifLoading = false;
	isContentLoading = false;
	ifWorksLoading = false;
	ifLinksLoading = false;

	worksCountloaded = 0;
	worksLeftover = 0;
	linksCountloaded = 0;
	linksLeftover = 0;

	homeItemArray; // список всех ссылок и работ для вывода
	mastHead;
	works; // список работ
	links; // список ссылок
	worksMore = []; // работы при клике загрузить еще
	linksMore = []; // ссылки при клике загрузить еще

	constructor(
		private worksService: WorksService,
		private languageService: LanguageService,
		private translateService: TranslateService,
		private shareService: ShareService,
		private seoService: SeoService,
		private appService: AppService) {

		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				this.currentLang = event.lang;
				// когда выбрали язык, подгружаем
				this.getMasthead();
			});
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(true);

		// Выводим кнопку загрузить еще в футере
		// this.worksService.ShowLoadBtnSubject.next("work");

		const lang = this.languageService.currentLang;

		if (this.currentLang === undefined && lang) {
			this.currentLang = lang;
		}

		if (lang) {
			this.getMasthead();
			this.getLinks();
		}

		this.seoService.initMeta();

		// Обработка скролла для Lazy load
		this.appService.onScrollSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((topOffset) => {
				const heightPage = this.getHeightDocumentAndWindow();

				const dividend = (heightPage.docHeight ===  heightPage.windowHeight) ?
					0 : (topOffset / (heightPage.docHeight - heightPage.windowHeight));

				if (dividend > 0.9 && !this.isContentLoading) {
					this.isContentLoading = true;
					this.loadMoreLinks();
					this.loadMoreWorks();
				}
			});
	}

	getHeightDocumentAndWindow() {
		const body = document.body;
		const html = document.documentElement;
		const docHeight = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.clientHeight,
			html.scrollHeight,
			html.offsetHeight
		);

		return {
			docHeight: docHeight,
			windowHeight: html.clientHeight
		};
	}

	getMasthead() {
		if (this.ifLoading) {
			return;
		}
		this.mastHead = null;
		this.ifLoading = true;
		this.worksService.getMastHead(1).subscribe(
			response => {
				if (response && response.length) {

					// на главной только 1 мастхед
					const masthead = response[0];
					masthead["type"] = "masthead";
					masthead["mainImage"] = this.worksService.getMainImage(masthead.images);
					this.mastHead = masthead;
				} else {
					this.mastHead = [];
				}
				// Сначала загрузили мастхед, затем загружаем работы, чтоб исключить из них матхед
				// this.getWorks();
				this.getLinks();
			},
			err => {
				this.ifLoading = false;
				console.log(err);
			}
		);
	}

	getLinks() {
		this.links = null;
		this.worksService.getLinks(CONFIG.countLinkHome).subscribe(
			response => {
				if (response == null || typeof response !== "object") {
					return;
				}

				if (response[this.currentLang].items && response[this.currentLang].items.length) {
					this.links = response[this.currentLang].items;
					this.linksCountloaded += response[this.currentLang].items.length;
					this.linksLeftover = +response[this.currentLang].leftover;

					this.links.map(link => {
						link["type"] = "link";
					});
				} else {
					this.links = [];
				}

				// проверяем все ли данные загружены
				// this.checkIfLoadAllData();
				this.getWorks();
			},
			err => {
				this.ifLoading = false;
				console.log(err);
			}
		);
	}

	getWorks() {
		this.works = null;

		// Вычитаем из общего количества плиток количество links это и будет количество works, что надо грузить
		const countWorkHome = CONFIG.countTilesHome - this.links.length;

		this.worksService.getWorks(countWorkHome, null, null, this.mastHead.id).subscribe(
			response => {
				this.ifLoading = false;
				if (response && response.items && response.items.length) {
					this.works = response.items;
					this.worksCountloaded += response.items.length;
					this.worksLeftover = +response.leftover;

					this.works.map(work => {
						work["type"] = "work";
					});
				} else {
					this.works = [];
				}
				// if (response.length < CONFIG.countWorkHome) {
				// 	// если загрузилось меньше чем нужно было, значит больше работ нет и скрываем кнопку
				// 	this.worksService.ShowLoadBtnSubject.next(false);
				// }
				// проверяем все ли данные загружены
				this.checkIfLoadAllData();
			},
			err => {
				console.log(err);
				this.appService.togglePreloaderSubject.next(false);
				this.ifLoading = false;
			}
		);
	}

	loadMoreLinks() {
		if (this.ifLinksLoading || !this.linksLeftover) {
			return;
		}

		this.worksService.getLinks(CONFIG.countLinkHome, this.linksCountloaded).subscribe(
			response => {
				this.ifLinksLoading = false;

				if (response == null || typeof response !== "object") {
					return;
				}

				if (response[this.currentLang].items && response[this.currentLang].items.length) {
					this.linksMore = response[this.currentLang].items;
					this.linksCountloaded += response[this.currentLang].items.length;
					this.linksLeftover = +response[this.currentLang].leftover;

					this.linksMore.map(link => {
						link["type"] = "link";
					});
				} else {
					this.linksMore = [];
				}

				this.checkIfLoadDataMore();
			},
			err => {
				this.ifLinksLoading = false;
				console.log(err);
			}
		);
	}

	loadMoreWorks() {
		if (this.ifWorksLoading || !this.worksLeftover) {
			return;
		}

		// Вычитаем из общего количества плиток количество links это и будет количество works, что надо грузить
		const linksLeftover = (this.linksLeftover > CONFIG.countLinkHome) ? CONFIG.countLinkHome : this.linksLeftover;
		const countWorkHome = CONFIG.countTilesHome - linksLeftover;

		this.worksService.getWorks(countWorkHome, this.worksCountloaded, null, this.mastHead.id).subscribe(
			response => {
				this.ifWorksLoading = false;

				if (response && response.items && response.items.length) {
					this.worksMore = response.items;
					this.worksCountloaded += response.items.length;
					this.worksLeftover = +response.leftover;

					this.worksMore.map(work => {
						work["type"] = "work";
					});
				} /*else {
					this.worksMore = [];
					// если вообще нет работ значит больше работ нет и скрываем кнопку
					this.worksService.ShowLoadBtnSubject.next(false);
				}*/

				this.checkIfLoadDataMore();
			},
			err => {
				this.ifWorksLoading = false;
				console.log(err);
			}
		);
	}

	resetStateAfterLoaded() {
		this.ifLinksLoading = false;
		this.ifWorksLoading = false;
		this.linksMore = [];
		this.worksMore = [];
		this.isContentLoading = false;
	}

	checkIfLoadAllData() {
		if (!this.links || !this.works || !this.mastHead) {
			// Проверяем чтоб все данные для отображения были загружены
			return;
		}
		if (this.mastHead.length) {
			// Удаляем masthead из работ, т.к. мы запросили их отдельно
			this.works = this.worksService.removeMastheadFromWorks(this.works, this.mastHead);
		}
		// Создаем даты для сортировки
		this.works = this.setDataArray(this.works, "publication_date");
		this.links = this.setDataArray(this.links, "publication_date");

		// Объединяем массивы
		const allDataArray = this.works.concat(this.links);

		// Сортируем по дате
		this.homeItemArray = this.worksService.sortedArray(allDataArray, "timestamp");

		this.appService.togglePreloaderSubject.next(false);
	}

	checkIfLoadDataMore() {
		if ((this.worksLeftover && !this.worksMore.length) || (this.linksLeftover && !this.linksMore.length)) {
			// Проверяем чтоб все данные для отображения были загружены
			return;
		}

		// Если в базе больше нет работ и сообщений скрываем кнопку
		if ((typeof this.worksLeftover === 'number' && this.worksLeftover < 1)
			&& (typeof this.linksLeftover === 'number' && this.linksLeftover < 1)) {
			this.worksService.ShowLoadBtnSubject.next(false);
		}

		if (this.mastHead.length) {
			// Удаляем masthead из работ, т.к. мы запросили их отдельно
			this.worksMore = this.worksService.removeMastheadFromWorks(this.worksMore, this.mastHead);
		}
		// Создаем даты для сортировки
		this.worksMore = this.setDataArray(this.worksMore, "publication_date");
		this.linksMore = this.setDataArray(this.linksMore, "publication_date");

		// Объединяем массивы
		let allDataArray = this.worksMore.concat(this.linksMore);

		// Сортируем по дате
		allDataArray = this.worksService.sortedArray(allDataArray, "timestamp");

		this.gridWorks.showMoreWorks(allDataArray);
		this.resetStateAfterLoaded();
	}

	setDataArray(array, field) {
		/*Добавляем поле с датой */
		array = this.worksService.setTimeStamp(array, field);
		array = this.worksService.sortedArray(array, "timestamp");
		return array;
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}

}

