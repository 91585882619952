import {Component, OnInit} from '@angular/core';
import {EducationService, ModalService} from "@services";
import {FormControl, FormGroup, Validators} from "@angular/forms";


@Component({
	selector: 'app-form-new-course',
	templateUrl: './form-new-course.component.html',
	styleUrls: ['./form-new-course.component.scss']
})
export class FormNewCourseComponent implements OnInit {
	formError = false;
	errorMessage = '';

	formEmail = new FormGroup({
		'email': new FormControl()
	});

	constructor(private api: EducationService,
				private modalService: ModalService) {
	}

	ngOnInit() {
		this.formEmail = new FormGroup({
			email: new FormControl(null, [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]),
		});
	}

	onInputChange() {
		this.errorMessage = '';
	}

	onSubmit() {
		let form = this.formEmail.value;
		this.api.emailNewInternship(form)
			.subscribe(() => {
				this.formEmail.reset();
				this.modalService.open('successful-internship');
			}, error => {
				this.formError = true;
				this.errorMessage = error.error.message;
			});
	}
}
