import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
/*Config */
import { CONFIG } from "@config";
/*Services */
import { WorksService, LanguageService, ShareService, SeoService, AppService } from "@services";
/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
	selector: "all-works",
	templateUrl: "./all-works.component.html"
})

export class AllWorksComponent implements OnInit, OnDestroy {

	@ViewChild("gridWorks") gridWorks;

	unsubscribeAll = new Subject();

	ifLoadingGetWorks = false;
	ifLoadingGetMasthead = false;

	worksCountloaded = 0;
	worksLeftover = 0;
	works = [];
	worksMore = []; // работы при клике загрузить еще

	homeItemArray;
	mastHeads: Array<any> = [];
	currentFilter: string;
	mastHeadsId: Array<number> = [];

	constructor(
		private worksService: WorksService,
		private languageService: LanguageService,
		private translateService: TranslateService,
		private shareService: ShareService,
		private seoService: SeoService,
		private appService: AppService) {

		if (location.search.indexOf("filter")) {
			// Получаем текущий фильтр из url
			const param = location.search.replace("?filter=", "");
			if (param) {
				this.currentFilter = param;
			} else {
				this.currentFilter = "new";
			}
		}

		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				this.getMastHead(5);
			});

		this.worksService.clickLoadWorksObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(() => {
				/*Кликнули в футере загрузить еще */
				this.loadMoreWorks();
			});

		this.worksService.filterWorksObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(filter => {
				// Изменили фильтр
				this.currentFilter = filter;
				if (this.currentFilter) {
					this.homeItemArray = null;
					this.mastHeads = [];
					this.worksCountloaded = 0;
					this.worksLeftover = null;
					this.works = [];
					this.getMastHead(5);
					this.appService.togglePreloaderSubject.next(true);
				}

			});
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(true);

		this.worksService.toggleFilterWorkSubject.next(true);

		// Выводим кнопку загрузить еще в футере
		// this.worksService.ShowLoadBtnSubject.next("work");

		if (this.languageService.currentLang) {
			this.getMastHead(5);
		}

		// Обработка скролла для Lazy load
		this.appService.onScrollSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((topOffset) => {
				const heightPage = this.getHeightDocumentAndWindow();

				const dividend = (heightPage.docHeight ===  heightPage.windowHeight) ?
					1 : (topOffset / (heightPage.docHeight - heightPage.windowHeight));

				if (dividend > 0.9) {
					this.worksMore = [];

					this.loadMoreWorks();
				}
			});
	}

	getHeightDocumentAndWindow() {
		const body = document.body;
		const html = document.documentElement;
		const docHeight = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.clientHeight,
			html.scrollHeight,
			html.offsetHeight
		);

		return {
			docHeight: docHeight,
			windowHeight: html.clientHeight
		};
	}

	getMastHead(limit?) {
		if (this.ifLoadingGetMasthead) {
			return;
		}
		this.mastHeads = null;
		this.mastHeadsId = [];
		this.ifLoadingGetMasthead = true;

		this.worksService.getMastHead(limit, this.currentFilter).subscribe(
			response => {
				this.ifLoadingGetMasthead = false;

				if (response && response.length) {
					const mastheads = response;
					mastheads.map(masthead => {
						this.mastHeadsId.push(masthead.id);
						masthead["type"] = "masthead";
						masthead["mainImage"] = this.worksService.getMainImage(masthead.images);
					});
					this.mastHeads = mastheads;
				} else {
					this.mastHeads = [];
				}
				/*Сначала подгрузили мастхед, чтоб исключить из выдачи работ */
				this.getWorks();
			},
			err => {
				this.ifLoadingGetMasthead = false;
				console.log(err);
			}
		);
	}

	getWorks() {
		if (this.ifLoadingGetWorks) {
			return;
		}

		this.ifLoadingGetWorks = true;
		this.works = null;

		this.worksService.getFilterWorks(CONFIG.countWorkWorksPage, null, this.currentFilter, this.mastHeadsId).subscribe(
			response => {
				this.ifLoadingGetWorks = false;
				if (response && response.items && response.items.length) {
					this.works = response.items;
					this.worksCountloaded += response.items.length;
					this.worksLeftover = +response.leftover;

					this.works.map(work => {
						work["type"] = "work";
					});

					const heightPage = this.getHeightDocumentAndWindow();

					if (!!this.worksLeftover && heightPage.docHeight === heightPage.windowHeight) {
						this.loadMoreWorks();
					}
				} else {
					this.works = [];
				}

				// this.toggleLoadBtn();
				this.homeItemArray = this.works;
				this.appService.togglePreloaderSubject.next(false);
			},
			err => {
				this.appService.togglePreloaderSubject.next(false);
				this.ifLoadingGetWorks = false;
				console.log(err);
			}
		);
	}

	loadMoreWorks() {
		if (this.ifLoadingGetWorks || !this.worksLeftover) {
			return;
		}

		this.ifLoadingGetWorks = true;

		this.worksService.getFilterWorks(CONFIG.countWorkWorksPage, this.worksCountloaded, this.currentFilter, this.mastHeadsId).subscribe(
			response => {
				this.ifLoadingGetWorks = false;

				const works = response.items;

				if (response && response.items && response.items.length) {
					this.worksCountloaded += works.length;
					this.worksLeftover = +response.leftover;

					works.map(work => {
						work["type"] = "work";
					});
				}

				this.editWorksAfterLoadMore(works);
			},
			err => {
				this.ifLoadingGetWorks = false;
				console.log(err);
			}
		);
	}

	editWorksAfterLoadMore(works) {
		works = this.worksService.setTimeStamp(works, "created_at");
		works = this.worksService.sortedArray(works, "timestamp");
		// this.toggleLoadBtn();
		this.gridWorks.showMoreWorks(works);
	}

	toggleLoadBtn() {
		// Если в базе больше нет работ и сообщений скрываем кнопку
		if (typeof this.worksLeftover === "number" && this.worksLeftover < 1) {
			this.worksService.ShowLoadBtnSubject.next(false);
		} else {
			this.worksService.ShowLoadBtnSubject.next("work");
		}
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}

