import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {EducationService, ModalService, ScrollService} from "@services";
import {ActivatedRoute} from "@angular/router";

interface Question {
	question: string;
	answers: [
		{
			id: number;
			answer: string;
		}
	];
}

@Component({
	selector: 'app-career-test',
	templateUrl: './career-test.component.html',
	styleUrls: ['./career-test.component.scss']
})
export class CareerTestComponent implements OnInit {
	idActiveQuestion = 0;
	activeQuestion: Question;
	data: any;
	selectedOption = null;
	formData = {answers: []};
	resultTest: any;
	dataLoaded: boolean = false;

	@ViewChild('section1') section1!: ElementRef;
	@ViewChild('section2') section2!: ElementRef;


	constructor(private modalService: ModalService,
				private api: EducationService,
				private route: ActivatedRoute,
				private scrollService: ScrollService) {
	}

	ngOnInit() {
		this.api.getCareerTest().subscribe((response) => {
			this.data = response;
			this.activeQuestion = response[this.idActiveQuestion];
		});
	}

	closeModal(id: string) {
		this.idActiveQuestion = 0;
		this.activeQuestion = this.data[this.idActiveQuestion];
		this.formData = {answers: []};
		this.resultTest = null;
		this.selectedOption = null;
		this.dataLoaded= false;
		this.modalService.close(id);
	}

	prevQuestion() {
		--this.idActiveQuestion;
		this.selectedOption = null;
		this.formData.answers.pop();
		this.activeQuestion = this.data[this.idActiveQuestion];
	}

	nextQuestion() {
		++this.idActiveQuestion;
		this.activeQuestion = this.data[this.idActiveQuestion];
		this.formData.answers.push(this.selectedOption)
		this.selectedOption = null;
	}

	sendData() {
		this.formData.answers.push(this.selectedOption);
		this.api.postCareerTest(this.formData)
			.subscribe((response) => {
				this.resultTest = response;
				setTimeout(() => {
					this.resultTest.roles.forEach((role) => {
						role.progress = role.value
					});
					this.dataLoaded = true;
				}, 200);
			}, error => {
				console.log(error);
			});
	}

	roundNumber(num: number) {
		return Math.floor(num);
	}


	scrollToParentAnchor(): void {
		const anchorId = this.scrollService.getAnchorId();
		if (anchorId) {
			const element = document.getElementById(anchorId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				this.scrollService.clearAnchorId();
				this.modalService.close('career-test');
			}
		}
	}
}
