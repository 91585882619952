import { Component, Input, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment } from "@environments";
import { DomSanitizer, SafeUrl} from "@angular/platform-browser";

/*Services */
import { LanguageService } from "@services";
/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
@Component({
	selector: "tile-item",
	templateUrl: "./tile-item.component.html",
	styleUrls: ["./tile-item.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class TileItemComponent implements OnInit, OnDestroy {
	@Input("srcUrl") srcUrl;
	@Input("dataItem") dataItem;
	@Input("classList") classList;
	@Input("imageType") imageType;
	@Input("noUrlItem") noUrlItem;
	@Input("visibleVideo") visibleVideo;
	baseUrl: string = environment.url;
	currentLang = "";
	unsubscribeAll = new Subject();

	constructor(
		private languageService: LanguageService,
		private translateService: TranslateService,
		private sanitizer: DomSanitizer) {
		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				this.currentLang = event.lang;
			});

		this.languageService.SetLanguageObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(event => {
				this.currentLang = event;
			});
	}

	ngOnInit() {
		this.currentLang = this.languageService.currentLang;

		// FIX: Для формата 3х1 у link (aka message) не предусмотренно картинок. Поэтому применили это решение
		if (this.dataItem.type === "link" && this.imageType === "block3x1") {
			this.imageType = "block2x2";
		}
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}

	getBackgroundImage(url): SafeUrl {
		// tslint:disable-next-line:quotemark
		const style = 'url("' + this.baseUrl + url + '")';

		return this.sanitizer.bypassSecurityTrustStyle(style);
    }
}
