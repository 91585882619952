import {Component, Output, EventEmitter, OnInit, OnDestroy, ChangeDetectorRef} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
/*Services */
import { WorksService, ClientsService } from "@services";
/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
/*Config */
import { CONFIG } from "@config";
@Component({
	selector: "menu-header",
	templateUrl: "./menu-header.component.html"
})
export class MenuHeaderComponent implements OnInit, OnDestroy {
	currentLang = "";
	currentFilter: string; // Текущий фильтр
	ifActiveSubmenuWorks = false;
	ifActiveSubmenuClients = false;
	unsubscribeAll = new Subject();
	@Output() changeLang: EventEmitter<any> = new EventEmitter();
	@Output() clickItemMenu: EventEmitter<any> = new EventEmitter();

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private worksService: WorksService,
		private clientsService: ClientsService,
		private cdr: ChangeDetectorRef
	) {
		this.router.events
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					if (location.search.indexOf("filter")) {
						// Получаем текущий фильтр из url
						const param = location.search.replace("?filter=", "");
						if (param) {
							this.currentFilter = param;
						} else {
							this.currentFilter = "new";
						}
					}

					if (event.url.indexOf("work") < 0) {
						this.ifActiveSubmenuWorks = false;
						this.worksService.toggleFilterWorkSubject.next(this.ifActiveSubmenuWorks);
						this.cdr.detectChanges();
					}
					if (event.url.indexOf("clients") < 0) {
						this.ifActiveSubmenuClients = false;
						this.clientsService.toggleFilterClientsSubject.next(this.ifActiveSubmenuClients);
						this.cdr.detectChanges();
					}
				}
			});

		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				if (event.lang !== CONFIG.defaultLanguage) {
					this.currentLang = event.lang;
				} else {
					this.currentLang = "";
				}
			});

		this.worksService.toggleFilterWorkObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				this.ifActiveSubmenuWorks = value;
				this.cdr.detectChanges();
			});

		this.clientsService.toggleFilterClientsObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				this.ifActiveSubmenuClients = value;
				this.cdr.detectChanges();
			});
	}

	toggleLang(value?) {
		if (!value) {
			this.currentLang = "";
		} else {
			this.currentLang = value;
		}
		this.changeLang.emit(value);
	}

	toggleSubMenu() {
		// this.ifActiveSubmenuWorks = !this.ifActiveSubmenuWorks;
		// this.ifActiveSubmenuClients = !this.ifActiveSubmenuClients;
		// this.worksService.toggleFilterWorkSubject.next(this.ifActiveSubmenuWorks);
		// this.clientsService.toggleFilterClientsSubject.next(this.ifActiveSubmenuClients);
	}

	isActive(instruction: any[]): boolean {
		return this.router.isActive(this.router.createUrlTree(instruction), false);

	}

	onClickItemMenu() {
		this.clickItemMenu.emit(true);
	}

	filterWorks(filter) {
		if (this.currentFilter !== filter) {
			this.currentFilter = filter;
			this.worksService.filterWorksSubject.next(this.currentFilter);
		}
	}

	filterClients(filter) {
		if (this.currentFilter !== filter) {
			this.currentFilter = filter;
			this.clientsService.filterClientsSubject.next(this.currentFilter);
		}
	}

	onClickItemMenuWorks() {
		this.onClickItemMenu();
		this.filterWorks("new");
	}

	onClickItemMenuClients() {
		this.onClickItemMenu();
		this.filterClients("new");
	}

	ngOnInit() {
		let count = 0;
		this.route.queryParamMap
			.subscribe(queryParams => {
				/**/
				const filterParam = queryParams.get("filter");
				if (count === 0 && filterParam) {
					count++;
					this.toggleSubMenu();
				}
			});
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}
