import {Injectable} from "@angular/core";
import {environment} from "@environments";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, throwError} from "rxjs";

@Injectable()
export class EducationService {

	constructor(
		private http: HttpClient
	) {
	}

	courseData(): Observable<any> {
		return this.http.get('assets/json/course-data.json');
	}

	creativeData(): Observable<any> {
		return this.http.get('assets/json/creative-data.json');
	}

	reviewsList(): Observable<any> {
		return this.http.get(`${environment.api_url}/intern/reviews`);
		//return this.http.get('api/backend/public/api/intern/reviews');
	}

	curators(id: number): Observable<any> {
		return this.http.get(`${environment.api_url}/intern/curators?direction=${id}`);
		//return this.http.get(`api/backend/public/api/intern/curators?direction=${id}`);
	}

	internshipsDirections(id: number): Observable<any> {
		return this.http.get(`${environment.api_url}/intern/internships?direction=${id}`);
		//return this.http.get(`api/backend/public/api/intern/internships?direction=${id}`);
	}

	emailNewInternship(formData): Observable<any> {
		return this.http.post<any>(`${environment.api_url}/intern/left-email`, formData);
		//return this.http.post<any>('api/backend/public/api/intern/left-email', formData);
	}

	signUpInternship(formData): Observable<any> {
		return this.http.post<any>(`${environment.api_url}/intern/intern-request`, formData);
		//return this.http.post<any>('api/backend/public/api/intern/intern-request', formData);
	}

	getCareerTest(): Observable<any> {
		return this.http.get<any>(`${environment.api_url}/intern/test`);
		//return this.http.get<any>('api/backend/public/api/intern/test');
	}

	postCareerTest(formData): Observable<any> {
		return this.http.post<any>(`${environment.api_url}/intern/test-calculate`, formData);
		//return this.http.post<any>('api/backend/public/api/intern/test-calculate', formData);
	}
}
