import { Component, Input, OnInit, HostListener } from "@angular/core";

/*Services */
import { AppService } from "@services";

@Component({
	selector: "two-column-tile",
	templateUrl: "./two-column-tile.component.html"
})
export class TwoColumnTileComponent implements OnInit {
	@Input("srcUrl") srcUrl;
	@Input("dataItems") dataItems;

	constructor(
		private appService: AppService
	) { }

	ngOnInit() {
		setTimeout(() => {
			this.calcHeight();
			this.appService.animateShowSubject.next("true");
		});
	}

	calcHeight() {
		let percent;
		const tiles = document.querySelectorAll(".plate-js");

		if (window.innerWidth < 768) {
			percent = 48.5 / 100;
		} else if (window.innerWidth < 992) {
			percent = 19 / 100;
		} else {
			percent = 15.78 / 100;
		}

		for (let index = 0; index < tiles.length; index++) {
			const element = tiles[index];
			if (this.appService.isVisible(element)) {
				element.setAttribute("style", "height:" + document.getElementsByClassName("grid-tiles")[0].clientWidth * percent + "px");
			}
		}
	}

	showMore() {
		setTimeout(() => {
			this.calcHeight();
			this.appService.animateShowSubject.next("true");
		}, 100);
	}

	@HostListener("window:resize", ["$event"])
	onResize() {
		// При изменеии ширины пересчитываем выосоту блоков
		this.calcHeight();
	}

}
