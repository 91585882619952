import {Component, ViewEncapsulation, OnDestroy, OnInit, ChangeDetectorRef} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/* Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

/* Config */
import { CONFIG } from "@config";

/* Services */
import { AppService, WorksService, ClientsService } from "@services";
import { IFilterMenu } from "../interfaces";

@Component({
	selector: "header-app",
	templateUrl: "./header.component.html",
	styleUrls: ["header.component.scss"],
	encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit, OnDestroy {
	header: HTMLElement;
	currentLang = "";
	nextLang = "";
	isOpenMobile = false;
	pageScrollValue = 0;
	unsubscribeAll = new Subject();
	currentFilter: string;
	currentFilterWorks;
	console = console;
	location = location;
	ifActiveFilterWorks = false;
	ifActiveFilterClients = false;
	filterMenu: IFilterMenu[] = [];
	isSearchHeaderOpen = false;

	constructor(
		private router: Router,
		private translateService: TranslateService,
		private appService: AppService,
		private worksService: WorksService,
		private clientsService: ClientsService,
		private cdr: ChangeDetectorRef
	) {
		this.router.events
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					this.isOpenMobile = false;

					// Фильтры
					if (event.url.indexOf("work") < 0) {
						this.ifActiveFilterWorks = false;
						this.worksService.toggleFilterWorkSubject.next(this.ifActiveFilterWorks);
						this.cdr.detectChanges();
					}
					if (event.url.indexOf("clients") < 0) {
						this.ifActiveFilterClients = false;
						this.clientsService.toggleFilterClientsSubject.next(this.ifActiveFilterClients);
						this.cdr.detectChanges();
					}
				}
			});

		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				this.currentLang = event.lang;
				this.getFilters();

				if (event.lang !== CONFIG.defaultLanguage) {
					this.nextLang = event.lang;
				} else {
					this.nextLang = "";
				}
			});

		this.appService.onScrollSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((scroll) => {
				this.onWindowScroll(scroll);
			});

		this.worksService.filterWorksObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(filter => {
				// Изменили фильтр
				this.currentFilter = filter;
			});
		this.clientsService.filterClientsObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(filter => {
				// Изменили фильтр
				this.currentFilter = filter;
			});

		this.worksService.toggleFilterWorkObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				this.ifActiveFilterWorks = value;
				this.cdr.detectChanges();
			});

		this.clientsService.toggleFilterClientsObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				this.ifActiveFilterClients = value;
				this.cdr.detectChanges();
			});
	}

	ngOnInit() {
		this.header = document.getElementById("header");

		if (this.nextLang) {
			this.getFilters();
		}
	}

	changeLang(lang?) {
		const pathname = decodeURI(location.pathname);
		let newUrl;

		if (lang) {
			newUrl = pathname.replace(this.nextLang, lang);
		} else {
			newUrl = pathname.replace("/" + this.nextLang, "");
		}

		if (location.search.indexOf("filter") >= 0 && this.currentFilter) {
			this.router.navigate([newUrl], { queryParams: { filter: this.currentFilter } });
		} else {
			this.router.navigate([newUrl]);
		}
	}

	openMobileMenu() {
		this.isOpenMobile = true;
	}

	hideMobileMenu() {
		this.isOpenMobile = false;
	}

	onWindowScroll(verticalOffset) {
		// Do some stuff here when the window is scrolled
		const header = document.getElementById("header");
		const seachQuery = document.getElementById("search-query");

		if (this.pageScrollValue !== verticalOffset && !seachQuery && !this.isSearchHeaderOpen) {
			if (this.pageScrollValue > 300) {
				if (this.pageScrollValue < verticalOffset) {
					this.hideHeader();
				} else {
					this.showHeader();
				}
			} else {
				this.showHeader();
			}

			this.pageScrollValue = verticalOffset;
		}
	}

	hideHeader() {
		this.header.classList.add("header--minimal");
		this.header.classList.remove("show-elements");
	}

	showHeader() {
		this.header.classList.add("show-elements");
		this.header.classList.remove("header--minimal");
	}

	getFilters() {
		this.worksService.getFilters().subscribe(
			response => {
				if (response && response[this.currentLang].length) {
					const filterMenu = response[this.currentLang];

					filterMenu.map(item => {
						item["name"] = item.name;
					});

					this.filterMenu = filterMenu;
					this.worksService.filterMenuWorkSubject.next(filterMenu);
				} else {
					this.filterMenu = [];
				}
			},
			err => {
				console.log(err);
			}
		);
	}

	onSearchHeaderToggle(isOpen = false) {
		this.isSearchHeaderOpen = isOpen;

		if (isOpen) {
			this.showHeader();
		}
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}

