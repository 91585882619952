export const contactInfo = {
	title: {
		ru: "Как добраться",
		en: "How to get"
	},
	getInfo: {
		ru: `
				<b>На автомобиле:</b>
				Если вы планируете приехать к нам в офис на автомобиле, вам необходимо заказать пропуск. Это может сделать тот
				человек,
				с&nbsp;которым вы планируете встретиться. Охранник поможет вам поставить автомобиль на свободное парковочное место.
				Пожалуйста, оставляйте автомобили только там, где укажет охранник, поскольку часть мест на парковке бизнес-центра
				закреплена за конкретными автомобилями.
				<br><br>
				<b>Пешком от метро:</b>
				От <span class="green">м. Павелецкая</span> – пешком (15-20 минут) или на общественном транспорте (автобусы №№ 13,
				106, 158, 632
				или
				маршрутное
				такси № 13 до остановки «1-й Дербеневский пер.» или трамвай №№ 35, 38 до остановки «Новоспасский мост»)
				<br><br>
				<b>Пешком от метро:</b>
				От <span class="purple">м. Пролетарская</span> – трамвай №№ 35, 38 до остановки «Новоспасский мост» или автобус №
				184 до
				остановки
				«Дербеневская
				набережная, д. 7»`,
		en: `
				<b>By car:</b>
				If you are planning to go by car it is necessary to order an entry pass. This can be done by a person you’re visiting.
				A person from security will help you to park your car in a free parking lot. Please park your car only in the designated lot,
				because some of the lots are reserved to particular cars.
				<br><br>
				<b>By public transit:</b>
				<span class="green">From Paveletskaya metro station:</span>15-20 minutes by foot or bus routes No. 13, 106, 158, 632,
				taxi bus No. 13 to the stop “1st Derbenevskiy pereulok”;
				tram No. 35 and 38 to the stop Novospassky Most
				<br><br>
				<b>By public transit:</b>
				<span class="purple">From Proletarskaya metro station</span> – Tram No. 35 and 38 to the stop Novospassky Most and 10 minutes by foot`,
	},
	contactInfo: {
		ru: `
				115114, Москва,<br>
				Дербеневская наб., д. 7, стр. 13<br>
				Бизнес-центр «Новоспасский»
				<br><br>
				Тел. <a href="tel:+74957875778">+7 (495) 787-57-78</a><br>
				Факс +7 (495) 961-22-68
				<br><br>
				По вопросам нового бизнеса<br>
				<a href="mailto:new@bbdo.ru">new@bbdo.ru</a>
				<br><br>
				Если вы хотите у нас работать<br>
				<a href="mailto:hr@bbdo.ru">hr@bbdo.ru</a>
				<br><br>
				Для представителей СМИ<br>
				<a href="mailto:press-service@bbdo.ru">press-service@bbdo.ru</a>
				<br><br>
				Если вам нужны спикеры<br>
				на мероприятия<br>
				<a href="mailto:press-service@bbdo.ru">press-service@bbdo.ru</a>`,
		en: `
				7 Derbenevskaya emb., bldg. 13<br>
				115114, Moscow<br>
				Business centre “Novospasskiy”
				<br><br>
				Phone <a href="tel:+74957875778">+7 (495) 787-57-78</a><br>
				Fax +7 (495) 961-22-68
				<br><br>
				For new business inquiries<br>
				<a href="mailto:new@bbdo.ru">new@bbdo.ru</a><br>
				If you want to work with us<br>
				<a href="mailto:hr@bbdo.ru">hr@bbdo.ru</a>
				<br><br>
				For media inquiries<br>
				<a href="mailto:press-service@bbdo.ru">press-service@bbdo.ru</a>`
	}
};
