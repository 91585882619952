import {Component, OnInit, ViewChild, HostListener, OnDestroy, PLATFORM_ID, Inject} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";

/*Services */
import { LanguageService, WorksService, ClientsService, AppService, SearchService, SeoService, ShareService } from "@services";

/*Config */
import { CONFIG } from "@config";
import {isPlatformBrowser} from "@angular/common";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html"
})

export class AppComponent implements OnInit, OnDestroy {

	@ViewChild("videoPlayer") videoPlayer;
	currentLang: string; // Текущий язык
	unsubscribeAll = new Subject();
	ifActiveSubmenuWorks = false;
	ifActiveSubmenuClients = false;
	ifshowSearch = false;
	hiddenNavigateElement = false;
	readonly isBrowser: boolean;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private route: ActivatedRoute,
		private languageService: LanguageService,
		private worksService: WorksService,
		private clientsService: ClientsService,
		private appService: AppService,
		private searchService: SearchService,
		private seoService: SeoService,
		private shareService: ShareService,
		@Inject(PLATFORM_ID) private platformId
	) {
		this.isBrowser = isPlatformBrowser(this.platformId)
		translate.addLangs(CONFIG.languages);
		translate.setDefaultLang(CONFIG.defaultLanguage);

		this.router.events
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					window.scrollTo(0, 0);
					this.searchService.showSearchInputSubject.next(false);

					const urlLang = route.snapshot.children[0].params["lang"];

					if (urlLang) {
						if (CONFIG.languages.indexOf(urlLang) < 0) {
							this.router.navigate(["404"]);
							return;
						}
						if (urlLang !== this.currentLang) {
							this.currentLang = urlLang;
							this.translate.use(this.currentLang);
						}
					} else {
						if (this.currentLang !== CONFIG.defaultLanguage) {
							this.currentLang = CONFIG.defaultLanguage;
							translate.use(CONFIG.defaultLanguage);
						}
					}

					setTimeout(() => {
						this.languageService.SetLanguageSubject.next(this.currentLang);
						this.languageService.currentLang = this.currentLang;
					});
					this.shareService.initShare(this.currentLang);
				}
			});

		this.worksService.playVideoObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				this.playVideo(value.id, value.type);
			});

		this.worksService.toggleFilterWorkObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				this.ifActiveSubmenuWorks = value;
			});

		this.clientsService.toggleFilterClientsObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				this.ifActiveSubmenuClients = value;
			});

		this.appService.animateShowObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				this.animateShow();
			});
		this.searchService.showSearchBlockObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				if (value && value.query && value.query.length) {
					this.ifshowSearch = true;
				} else {
					this.ifshowSearch = false;
				}
			});
	}

	ngOnInit() {
		this.detectedTouchScreen();

		this.router.events
			.filter((event) => event instanceof NavigationEnd)
			.map(() => this.route)
			.map((route) => {
				while (route.firstChild) { route = route.firstChild; }
				return route;
			})
			.filter((route) => route.outlet === "primary")
			.mergeMap((route) => route.data)
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event) => {
				if(event.hiddenNavigateElement) {
					this.hiddenNavigateElement = event.hiddenNavigateElement
				}
				this.seoService.setTitle(event["title"], this.currentLang);
				this.seoService.initMeta();
			});
		setTimeout(() => {
			this.onWindowScroll();
		});
		this.onWindowScroll();
	}

	detectedTouchScreen() {
		if (("ontouchstart" in window) || (navigator.msMaxTouchPoints > 0)) {
			document.body.classList.add("touch-device");
		} else {
			document.body.classList.remove("touch-device");
		}
	}

	playVideo(id, type) {
		/*Проигрываем видео по клику */
		this.videoPlayer.play(id, type);
	}

	@HostListener("window:scroll", []) onWindowScroll() {
		const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		this.appService.onScrollSubject.next(verticalOffset);
	}

	animateShow() {
		let index = 0;
		setTimeout(() => {
			const all = document.getElementsByClassName("animation-show");
			for (let i = 0, max = all.length; i < max; i++) {

				if (all[i] && !all[i].classList.contains("show")) {

					setTimeout(() => {
						if (all[i]) {
							all[i].classList.add("show");
						}
					}, index * 15);

					index++;
				}
			}
		});
	}

	onActivate() {
		if(this.isBrowser){
			window.scroll(0, 0)
		}
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}
