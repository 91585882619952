import { Component, HostListener, Input, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/* Services */
import { AppService, WorksService, LanguageService } from "@services";

/* Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
	selector: "grid-people",
	templateUrl: "grid-people.component.html"
})

export class GridPeopleComponent implements OnInit, OnDestroy {
	@Input() peopleData: object[] | undefined;
	unsubscribeAll = new Subject();
	currentLang = "";
	desktopData;
	tabletData;
	mobileData;
	desktopWorksCountTemplate = [4, 4, 6];
	tableWorksCountTemplate = [4, 5];
	mobileWorksCountTemplate = [2, 4, 3];

	constructor(
		private appService: AppService,
		private worksService: WorksService,
		private translateService: TranslateService,
		private languageService: LanguageService
	) {
		this.appService.onScrollSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((scroll) => {
				this.worksService.onWindowScroll();
			});

		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				this.currentLang = event.lang;
			});
	}

	@HostListener("window:resize", ["$event"])

	onResize() {
		// При изменеии ширины пересчитываем выосоту блоков
		this.worksService.calcHeight();
	}

	ngOnInit() {
		this.currentLang = this.languageService.currentLang;

		if (this.currentLang) {
			this.initData();
		}
	}

	ngOnChanges() {
		this.initData();
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}

	initData() {
		if (!this.peopleData) {
			return;
		}

		this.peopleData = this.randomizeArray(this.peopleData);
		this.peopleData = this.appService.addDataType('people', this.peopleData);
		this.desktopData = this.appService.sliceWorksArray(this.desktopWorksCountTemplate, this.peopleData);
		this.tabletData = this.appService.sliceWorksArray(this.tableWorksCountTemplate, this.peopleData);
		this.mobileData = this.appService.sliceWorksArray(this.mobileWorksCountTemplate, this.peopleData);

		setTimeout(() => {
			this.worksService.calcHeight();
			this.worksService.onWindowScroll();
			this.appService.animateShowSubject.next("true");
			this.appService.togglePreloaderSubject.next(false);
		}, 50);
	}

	randomizeArray(array) {
		return array.sort(function () { return 0.5 - Math.random(); });
	}
}
