import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

/* Services */
import { LanguageService, PeopleService, WorksService, SeoService, ShareService, AppService } from "@services";

/* Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

/* Config */
import { CONFIG } from "@config";

/* Environment */
import { environment } from "@environments";

@Component({
	selector: "detail-people",
	templateUrl: "./detail-people.component.html",
	styleUrls: ["./detail-people.component.scss"],
	encapsulation: ViewEncapsulation.None
})

export class DetailPeopleComponent implements OnInit, OnDestroy {
	@ViewChild("gridWorks") gridWorks;
	unsubscribeAll = new Subject();
	ifLoadingGetWorks = false;
	workCountloaded = 1;
	ifSendRequest = false;
	baseUrl: string = environment.url;
	currentLang: string;
	backUrl: string;
	personId;
	personData;
	works;
	worksArray;

	constructor(
		private worksService: WorksService,
		private translateService: TranslateService,
		private languageService: LanguageService,
		private peopleService: PeopleService,
		private router: Router,
		private route: ActivatedRoute,
		private seoService: SeoService,
		private shareService: ShareService,
		private appService: AppService
	) {
		this.router.events
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					this.personId = route.snapshot.params.id;
					if (!this.ifSendRequest && this.personId && this.currentLang) {
						this.getPersonInfo();
						this.getWorksPerson();
					}
				}
			});

		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				this.currentLang = event.lang;
				this.backUrl = (this.currentLang !== "ru") ? "/" + this.currentLang: "/";

				if (!this.ifSendRequest && this.personId) {
					this.getPersonInfo();
					this.getWorksPerson();
				}
			});

		this.peopleService.clickLoadWorksPersonObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(() => {
				/*Кликнули в футере загрузить еще */
				this.getMoreWorks();
			});
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(true);

		const lang = this.languageService.currentLang;

		if (this.currentLang === undefined && lang) {
			this.currentLang = lang;
		}

		if (this.currentLang && !this.ifSendRequest) {
			this.backUrl = (this.currentLang !== "ru") ? "/" + this.currentLang: "/";

			this.getPersonInfo();
			this.getWorksPerson();
		}
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}

	getPersonInfo() {
		this.ifSendRequest = true;
		this.personData = null;

		this.peopleService.getPersonInfo(this.personId).subscribe(
			response => {
				if (response) {
					this.personData = response[this.currentLang];
					this.seoService.setApiTitle(this.personData.name);
					this.seoService.initMeta();
				} else {
					this.router.navigate([this.backUrl, "people"]);
				}

				this.appService.togglePreloaderSubject.next(false);
			},
			err => {
				this.router.navigate([this.backUrl, "people"]);
				this.appService.togglePreloaderSubject.next(false);
				console.log(err);
			}
		);
	}

	getWorksPerson() {
		this.works = null;

		this.peopleService.getPersonWorks(this.personId, CONFIG.countWorkPerson).subscribe(
			response => {
				if (response && response.length) {
					this.works = response;
					this.works.map(work => {
						work["type"] = "work";
					});
				} else {
					this.works = [];
				}
				if (response.length < CONFIG.countWorkPerson) {
					// если загрузилось меньше чем нужно было, значит больше работ нет и скрываем кнопку
					this.worksService.ShowLoadBtnSubject.next(false);
				} else {
					this.worksService.ShowLoadBtnSubject.next("workPerson");
				}


				this.works = this.worksService.setTimeStamp(this.works, "created_at");
				this.works = this.worksService.sortedArray(this.works, "timestamp");

				this.worksArray = this.works;

			},
			err => {
				console.log(err);
			}
		);
	}

	getMoreWorks() {
		if (this.ifLoadingGetWorks) {
			return;
		}
		this.ifLoadingGetWorks = true;
		this.worksService.getFilterWorks(CONFIG.countWorkWorksPage, this.workCountloaded).subscribe(
			response => {
				this.ifLoadingGetWorks = false;
				this.workCountloaded++;

				if (!response.length) {
					this.worksService.ShowLoadBtnSubject.next(false);
					return;
				}

				if (response.length < CONFIG.countWorkWorksPage) {
					this.worksService.ShowLoadBtnSubject.next(false);
				}

				const works = response;

				if (works && works.length) {
					works.map(work => {
						work["type"] = "work";
					});
				}
				this.addWorksAfterLoadMore(works);
			},
			err => {
				this.ifLoadingGetWorks = false;
				console.log(err);
			}
		);
	}

	addWorksAfterLoadMore(works) {
		works = this.worksService.setTimeStamp(works, "created_at");
		works = this.worksService.sortedArray(works, "timestamp");
		this.gridWorks.showMoreWorks(works);
	}
}


