import { Component, ViewChild, ViewEncapsulation, OnDestroy, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/* Services */
import { LanguageService, SearchService } from "@services";

/* Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { CompleterCmp } from "ng2-completer";

/* Config */
import { CONFIG } from "@config";

import { ICustomCompleterItem } from "app/core/models/custom-compiler-item.model";

import { SearchCustomData } from "app/utils/search-custom-data";

@Component({
	selector: "search-header",
	templateUrl: "./search-header.component.html",
	styleUrls: ["search-header.component.scss"],
	encapsulation: ViewEncapsulation.None
})

export class SearchHeaderComponent implements OnDestroy, OnInit {
	unsubscribeAll = new Subject();
	searchQuery = "";
	rawSearchQuery = "";
	isOpen = false;
	currentLang: string;
	data: SearchCustomData;

	@ViewChild("autocompleteSearch") private autocompleteSearch: CompleterCmp;

	@Output() toggle: EventEmitter<any> = new EventEmitter();

	constructor(
		private router: Router,
		private translateService: TranslateService,
		private languageService: LanguageService,
		private searchService: SearchService,
		private http: HttpClient
	) {
		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				if (event.lang !== CONFIG.defaultLanguage) {
					this.currentLang = event.lang;
				} else {
					this.currentLang = "";
				}
			});

		this.searchService.showSearchInputObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				if (!value) {
					this.searchQuery = "";
				}

				this.toggleSearch(value);
			});
	}

	ngOnInit() {
		const lang = this.languageService.currentLang;
		
		if (lang) {
			if (lang !== CONFIG.defaultLanguage) {
				this.currentLang = lang;
			} else {
				this.currentLang = "";
			}
		}

		this.data = new SearchCustomData(this.http, this.translateService);
	}

	toggleSearch(value?) {
		if (value !== undefined) {
			this.isOpen = value;
		} else {
			this.isOpen = !this.isOpen;
		}

		if (this.isOpen) {
			setTimeout(() => {
				this.autocompleteSearch.focus();

				// Fix for iOS
				const test = document.getElementById("autocompleteSearch");
				
				test.click();
				test.focus();
			}, 100);
		}

		this.toggle.emit(this.isOpen);
	}

	onKeyup() {
		if (this.searchQuery.length < 3) {
			return;
		}

		this.rawSearchQuery = this.searchQuery.trim();
		this.autocompleteSearch.open();
	}

	onKeydown(event) {
		if (this.searchQuery.length < 3) {
			return;
		}

		if (event.key === "Enter") {
			this.router.navigate([`/${this.currentLang}`, "search", "works", this.rawSearchQuery])
				.then(() => {
					this.autocompleteSearch.blur();
				});
		} else {
			this.autocompleteSearch.open();
		}
	}

	onSelected(selected: ICustomCompleterItem) {
		if (!selected || !selected.originalObject) {
			return;
		}

		if (selected.category === "people") {
			this.router.navigate([`/${this.currentLang}`, "people", selected.originalObject.id]);
		} else if (selected.category === "works") {
			this.router.navigate([`/${this.currentLang}`, "search", selected.category, this.rawSearchQuery]);
		} else {
			this.router.navigate([`/${this.currentLang}`, "search", selected.category, selected.originalObject.slug]);
		}

		this.searchQuery = "";
		this.autocompleteSearch.blur();
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}
