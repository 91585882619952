import {Component, OnInit} from '@angular/core';
import {AppService, EducationService, ModalService, ScrollService, VideoService} from "@services";
import Swiper from 'swiper';
import {environment} from "@environments";

@Component({
	selector: 'app-education',
	templateUrl: './education.component.html',
	styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {

	protected readonly environment = environment;
	courseData;
	reviewsData;
	activeSlide;

	constructor(private api: EducationService,
				private modalService: ModalService,
				private videoService: VideoService,
				private scrollService: ScrollService,
				private appService: AppService) {
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(true);
		this.api.courseData().subscribe((response) => {
			this.courseData = response;
		});

		this.api.reviewsList().subscribe((response) => {
			this.reviewsData = response;
			setTimeout(() => {
				this.initSlider();
				this.appService.togglePreloaderSubject.next(false);
			}, 1000)
		});

		this.scrollService.setAnchorId('parentComponentAnchor');
	}

	initSlider() {
		let clickedSlide;
		const handleSlideClick = (clickedIndex) => {
			if (clickedIndex !== undefined) {
				const clickedSlide = slider.slides[clickedIndex];
				const clickedSlideId = clickedSlide.id;
				this.activeSlide = this.reviewsData.find(obj => obj.id == clickedSlideId);
				this.videoService.sendData(this.activeSlide);
				this.openModal('video');
			}
		};

		const handleNextSlideTransitionEnd = () => {
			if (slider && slider.activeIndex !== undefined) {
				let nextSlideIndex = ++clickedSlide;
				if(nextSlideIndex === slider.slides.length) {
					clickedSlide = 0
					nextSlideIndex = 0;
				}
				if (nextSlideIndex < slider.slides.length) {
					const nextSlideId = slider.slides[nextSlideIndex].id;
					this.activeSlide = this.reviewsData.find(obj => obj.id == nextSlideId);
					this.videoService.sendData(this.activeSlide);
				}
			}
		};

		const handlePrevSlideTransitionEnd = () => {
			if (slider && slider.activeIndex !== undefined) {
				const prevSlideIndex = --clickedSlide;
				if(prevSlideIndex === 0){
					clickedSlide = slider.slides.length;
				}
				if (prevSlideIndex >= 0) {
					const prevSlideId = slider.slides[prevSlideIndex].id;
					this.activeSlide = this.reviewsData.find(obj => obj.id == prevSlideId);
					this.videoService.sendData(this.activeSlide);
				}
			}
		};

		const slider = new Swiper(".swiper-container", {
			loop: true,
			speed: 800,
			slidesPerView: 'auto',
			centeredSlides: true,
			effect: 'coverflow',
			shadow: false,
			coverflowEffect: {
				rotate: 50,
				stretch: 0,
				depth: 100,
				modifier: 1,
				slideShadows: true,
			},
			grabCursor: true,
			parallax: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			navigation: {
				nextEl: '.reviews__slider-button-next',
				prevEl: '.reviews__slider-button-prev',
			},
			on: {
				click: () => {
					clickedSlide = slider.clickedIndex;
					handleSlideClick(clickedSlide);
				},
				slideNextTransitionEnd: handleNextSlideTransitionEnd,
				slidePrevTransitionEnd: handlePrevSlideTransitionEnd,
			}
		});
	}

	openModal(id: string) {
		this.modalService.open(id);
	}
}
