import {Component, OnInit} from '@angular/core';
import {ModalService} from "@services";

@Component({
	selector: 'app-successful-sending',
	templateUrl: './successful-sending.component.html',
	styleUrls: ['./successful-sending.component.scss']
})
export class SuccessfulSendingComponent implements OnInit {

	constructor(private modalService: ModalService) {
	}

	ngOnInit() {
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}
}
