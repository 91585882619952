import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

/*Services */
import { LanguageService, WorksService, PeopleService, ShareService, SeoService, AppService } from "@services";

/*Config */
import { CONFIG } from "@config";

@Component({
	selector: "all=people",
	templateUrl: "./all-people.component.html"
})

export class AllPeopleComponent implements OnInit, OnDestroy {
	unsubscribeAll = new Subject();
	isDataLoading = false;
	currentLang: string;
	peopleData;

	constructor(
		private worksService: WorksService,
		private translateService: TranslateService,
		private languageService: LanguageService,
		private peopleService: PeopleService,
		private shareService: ShareService,
		private seoService: SeoService,
		private appService: AppService
	) {
		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				this.currentLang = event.lang;
			});
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(true);

		const lang = this.languageService.currentLang;

		if (this.currentLang === undefined && lang) {
			this.currentLang = lang;
		}

		this.seoService.initMeta();

		this.getData();
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}

	getData() {
		if (this.isDataLoading) {
			return;
		}

		this.isDataLoading = true;

		this.peopleService
			.getPersonList()
			.subscribe(
				(response) => {
					if (response === null || typeof response !== "object") {
						return;
					}

					this.appService.togglePreloaderSubject.next(false);

					this.isDataLoading = false;

					this.peopleData = response[this.currentLang];
				},
				(err) => {
					this.appService.togglePreloaderSubject.next(false);

					this.isDataLoading = false;

					console.log(err);
				}
			);
	}
}


