import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cookie-popup",
  templateUrl: "./cookie-popup.component.html",
  styleUrls: ["./cookie-popup.component.scss"]
})
export class CookiePopupComponent implements OnInit {
	hidden = false;

  	constructor() { }

	ngOnInit() {
		const cookie = JSON.parse(localStorage.getItem("cookie") || "{}").cookie;
		this.hidden = !!cookie;
	}

	hiddenPopup() {
		localStorage.setItem("cookie", JSON.stringify({ cookie: true }));
		this.hidden = true;
	}

}
