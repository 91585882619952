export const CONFIG = {
	languages: ["en", "ru"],
	defaultLanguage: "ru",
	countTilesHome: 19,
	countWorkHome: 10,
	countLinkHome: 9,
	countWorkWorksPage: 19,
	countWorkPerson: 26,
	countClients: 12
};

