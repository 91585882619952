import { Meta, Title } from "@angular/platform-browser";
import { takeUntil } from "rxjs/operators";
import { Injectable, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";

/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

/*Service */
import { ShareService } from "./share.services";

@Injectable()
export class SeoService implements OnInit {
	unsubscribeAll = new Subject();
	currentLang: string;
	currentTypePage: string;
	titleArray = {
		home: {
			ru: "BBDO Moscow",
			en: "BBDO Moscow"
		},
		work: {
			ru: "Работы — BBDO Moscow",
			en: "The Work — BBDO Moscow"
		},
		work_detail: {
			ru: "BBDO Moscow",
			en: "BBDO Moscow"
		},
		clients: {
			ru: "Клиенты — BBDO Moscow",
			en: "Clients — BBDO Moscow"
		},
		people: {
			ru: "Люди — BBDO Moscow",
			en: "People — BBDO Moscow"
		},
		people_detail: {
			ru: "BBDO Moscow",
			en: "BBDO Moscow"
		},
		about: {
			ru: "О нас — BBDO Moscow",
			en: "About — BBDO Moscow"
		},
		contacts: {
			ru: "Контакты — BBDO Moscow",
			en: "Contacts — BBDO Moscow"
		},
		education: {
			ru: "Обучение — BBDO Moscow",
			en: "Education — BBDO Moscow"
		},
		"creative-course": {
			ru: "Обучение — BBDO Moscow",
			en: "Education — BBDO Moscow"
		},
		"strategists-course": {
			ru: "Обучение — BBDO Moscow",
			en: "Education — BBDO Moscow"
		},
		"accounts-course": {
			ru: "Обучение — BBDO Moscow",
			en: "Education — BBDO Moscow"
		},
		search: {
			ru: "Поиск — BBDO Moscow",
			en: "Search — BBDO Moscow"
		},
		"404": {
			ru: "Страница не найдена — BBDO Moscow",
			en: "Page not found — BBDO Moscow"
		},
	};
	constructor(
		private titleService: Title,
		private translateService: TranslateService,
		private shareService: ShareService
	) {
	}

	initMeta() {
		let description = "";
		if (document.querySelectorAll("meta[property='og:description']")[0]) {
			description = document.querySelectorAll("meta[property='og:description']")[0].getAttribute("content");
		}
		let image = "";
		if (document.querySelectorAll("meta[property='og:description']")[0]) {
			image = document.querySelectorAll("meta[property='og:image']")[0].getAttribute("content");
		}
		const title = document.title;
		const url = window.location.href;
		this.shareService.updateShare(title, description, url, image);
		this.setOgUrl(url);
		this.setOgImage(image);
	}
	setTitle(page, lang) {
		this.currentTypePage = page;
		this.currentLang = lang;
		const titlePage = this.titleArray[this.currentTypePage][this.currentLang];
		this.titleService.setTitle(this.titleArray[this.currentTypePage][this.currentLang]);
		this.setOgTitle(titlePage);
	}
	setApiTitle(title) {
		const titlePage = title + " — " + this.titleArray[this.currentTypePage][this.currentLang];
		this.titleService.setTitle(titlePage);
		this.setOgTitle(titlePage);
	}
	ngOnInit() {

	}
	setOgTitle(title) {
		document.querySelector("meta[property='og:title']").setAttribute("content", title);
	}
	setOgUrl(url) {
		document.querySelector("meta[property='og:url']").setAttribute("content", url);
	}
	setOgImage(image) {
		document.querySelector("meta[property='og:image']").setAttribute("content", image);
	}
}


