
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
	HomeComponent,
	AllWorksComponent,
	DetailWorkComponent,
	ClientsComponent,
	AllPeopleComponent,
	DetailPeopleComponent,
	AboutComponent,
	ContactComponent,
	SearchComponent,
	PageNotFoundComponent
} from "./view/pages";
import {EducationComponent} from "./view/pages/education/education.component";
import {CreativeCourseComponent} from "./view/pages/education/course-pages/creative-course/creative-course.component";
import {AccountsCourseComponent} from "./view/pages/education/course-pages/accounts-course/accounts-course.component";
import {
	StrategistsCourseComponent
} from "./view/pages/education/course-pages/strategists-course/strategists-course.component";

const routes: Routes = [
	{
		path: "",
		pathMatch: "full",
		component: HomeComponent,
		data: { title: "home" }
	},
	{
		path: "work",
		pathMatch: "full",
		component: AllWorksComponent,
		data: { title: "work" }
	},
	{
		path: ":lang/work",
		component: AllWorksComponent,
		data: { title: "work" }
	},
	{
		path: ":lang/work/:id",
		component: DetailWorkComponent,
		data: { title: "work_detail" }
	},
	{
		path: "work/:id",
		component: DetailWorkComponent,
		data: { title: "work_detail" }
	},
	{
		path: "clients",
		pathMatch: "full",
		component: ClientsComponent,
		data: { title: "clients" }
	},
	{
		path: ":lang/clients",
		component: ClientsComponent,
		data: { title: "clients" }
	},
	{
		path: ":lang/people",
		component: AllPeopleComponent,
		data: { title: "people" }
	},
	{
		path: "people",
		component: AllPeopleComponent,
		data: { title: "people" }
	},
	{
		path: ":lang/people/:id",
		component: DetailPeopleComponent,
		data: { title: "people_detail" }
	},
	{
		path: "people/:id",
		component: DetailPeopleComponent,
		data: { title: "people_detail" }
	},
	{
		path: "about",
		component: AboutComponent,
		data: { title: "about" }
	},
	{
		path: ":lang/about",
		component: AboutComponent,
		data: { title: "about" }
	},
	{
		path: "contacts",
		component: ContactComponent,
		data: { title: "contacts" }
	},
	{
		path: ":lang/contacts",
		component: ContactComponent,
		data: { title: "contacts" }
	},
	{
		path: "education",
		component: EducationComponent,
		data: { title: "education", hiddenNavigateElement: true }
	},
	{
		path: ":lang/education",
		component: EducationComponent,
		data: { title: "education", hiddenNavigateElement: true }
	},
	{
		path: "education/creative-course",
		component: CreativeCourseComponent,
		data: { title: "creative-course", hiddenNavigateElement: true }
	},
	{
		path: ":lang/education/creative-course",
		component: CreativeCourseComponent,
		data: { title: "creative-course", hiddenNavigateElement: true }
	},
	{
		path: "education/accounts-course",
		component: AccountsCourseComponent,
		data: { title: "accounts-course", hiddenNavigateElement: true }
	},
	{
		path: ":lang/education/accounts-course",
		component: AccountsCourseComponent,
		data: { title: "accounts-course", hiddenNavigateElement: true }
	},
	{
		path: "education/strategists-course",
		component: StrategistsCourseComponent,
		data: { title: "accounts-course", hiddenNavigateElement: true }
	},
	{
		path: ":lang/education/strategists-course",
		component: StrategistsCourseComponent,
		data: { title: "accounts-course", hiddenNavigateElement: true }
	},
	{
		path: "search",
		component: SearchComponent,
		data: { title: "search" }
	},
	{
		path: "search/:category/:query",
		component: SearchComponent,
		data: { title: "search" }
	},
	{
		path: ":lang/search",
		component: SearchComponent,
		data: { title: "search" }
	},
	{
		path: ":lang/search/:category/:query",
		component: SearchComponent,
		data: { title: "search" }
	},
	{
		path: "404",
		component: PageNotFoundComponent,
		data: { title: "404" }
	},
	{
		path: ":lang/404",
		component: PageNotFoundComponent,
		data: { title: "404" }
	},
	{
		path: ":lang",
		component: HomeComponent,
		data: { title: "home" }
	},
	{ path: "**", redirectTo: "" }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [
		RouterModule
	],
})

export class AppRoutingModule { }
