import { Component, HostListener, Input, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/*Services */
import { AppService, WorksService } from "@services";

@Component({
	selector: "grid-works",
	templateUrl: "grid-works.component.html"
})

export class GridWorksComponent implements OnInit, OnDestroy {
	@Input("works") works;
	@Input("srcUrl") srcUrl;

	unsubscribeAll = new Subject();

	// 3 разных массива для каждой версии экранов
	desktopWorks = [];
	tabletWorks = [];
	mobileWorks = [];

	// Кол-во блоков каждой версии (строки) для разных экранов
	desktopWorksCountTemplate = [7, 6, 6];
	tableWorksCountTemplate = [5, 5, 5];
	mobileWorksCountTemplate = [7, 6, 6];

	constructor(
		private appService: AppService,
		private worksService: WorksService) {
		this.appService.onScrollSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((scroll) => {
				this.worksService.onWindowScroll();
			});
	}

	ngOnInit() {
		// Загружаем работы для главной страницы
		if (this.works && this.works.length > 0) {
			this.loadWorks();
		}
	}

	loadWorks() {
		// Нарезаем все работы для каждой версии экрана
		this.desktopWorks = this.appService.sliceWorksArray(this.desktopWorksCountTemplate, this.works);
		this.tabletWorks = this.appService.sliceWorksArray(this.tableWorksCountTemplate, this.works);
		this.mobileWorks = this.appService.sliceWorksArray(this.mobileWorksCountTemplate, this.works);

		// Делаем небольшую задержку чтоб успели отрисоваться работы и высчитываем их высоту и показываем с анимацией
		setTimeout(() => {
			this.worksService.calcHeight();
			this.appService.animateShowSubject.next("true");
		}, 150);
	}

	showMoreWorks(works) {
		// Добавляем работы к каждой версии шаблонов
		this.desktopWorks = this.addWorks(works, this.desktopWorks, this.desktopWorksCountTemplate);
		this.tabletWorks = this.addWorks(works, this.tabletWorks, this.tableWorksCountTemplate);
		this.mobileWorks = this.addWorks(works, this.mobileWorks, this.mobileWorksCountTemplate);

		// Делаем небольшую задержку чтоб успели отрисоваться работы и высчитываем их высоту и показываем с анимацией
		setTimeout(() => {
			this.worksService.calcHeight();
			this.appService.animateShowSubject.next("true");
			// если тачскриновый экран = показываем
			this.worksService.onWindowScroll();
		}, 100);
	}

	addWorks(works, currentArrayWorks, worksCountTemplate) {
		/*Добавлем работы по клику подгрузить еще
			works - список добавляемых работ
			currentArrayWorks - текущий список работ
			worksCountTemplate - шаблон последней работы (чтоб новые работы продолжили корретный порядок шаблонов)
		*/
		// Экранируем массив
		const tempArrayWorks = JSON.parse(JSON.stringify(works));

		// Кол-во текущих работ
		const worksLength = currentArrayWorks.length;

		// последний шаблон в массиве работ
		const last = currentArrayWorks[worksLength - 1];

		// Сколько  должно быть работ в шаблоне
		const currentCount = worksCountTemplate[last.type];

		// Проверяем, если в последнем шаблоне не хватает работ, добавляем нужно кол-во
		if (currentCount !== last.data.length) {
			const data = currentArrayWorks[worksLength - 1].data;
			currentArrayWorks[worksLength - 1].data = data.concat(tempArrayWorks.splice(0, currentCount - last.data.length));
		}
		// Добавлем новые работы к текущим
		return currentArrayWorks.concat(this.appService.sliceWorksArray(worksCountTemplate, tempArrayWorks, last.type + 1));
	}

	@HostListener("window:resize", ["$event"])
	onResize() {
		// При изменеии ширины пересчитываем выосоту блоков
		if (this.desktopWorks) {
			this.worksService.calcHeight();
		}

	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}

