import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from "@services";

interface Card {
	title: string;
	photo: string;
	nameCourse: string;
	info: string[];
	link: string;
	name: string;
	description: string;
	"id": number;
	"circle_color": string,
	"circle_edge_color": string;
	"circle_text": string;
	"circle_text_color": string;
	"direction_id": number;
}

@Component({
	selector: 'app-course-card',
	templateUrl: './course-card.component.html',
	styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent implements OnInit {
	@Input() idDirection: number;
	@Input() data: Card;
	@Input() course: boolean;
	constructor(private modalService: ModalService) {
	}

	ngOnInit() {

	}

	openModal(id: string, idInternship?: number, data?: any) {
		this.modalService.open(id);
		this.modalService.dataCourse(data);
	}
}
