import { Component, ViewEncapsulation, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

/* Services */
import { SearchService, LanguageService } from "@services";

/* Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

/* Config */
import { CONFIG } from "@config";

import { IQuerySearch } from "app/core/models/search-query.model";

@Component({
	selector: "search-block",
	templateUrl: "./search-block.component.html",
	styleUrls: ["search-block.component.scss"],
	encapsulation: ViewEncapsulation.None
})

export class SearchBlockComponent implements OnInit {
	unsubscribeAll = new Subject();
	currentLang: string;
	currentQuery: string;
	searchQuery: IQuerySearch;

	constructor(
		private router: Router,
		private searchService: SearchService,
		private translateService: TranslateService,
		private languageService: LanguageService
	) {
		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				if (event.lang !== CONFIG.defaultLanguage) {
					this.currentLang = event.lang;
				} else {
					this.currentLang = "";
				}
			});

		this.searchService.showSearchBlockObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(query => {
				if (query && query.query && query.query.length > 0) {
					this.searchQuery = query;

					if (query.category === 'works') {
						this.currentQuery = `"${query.query}"`;
					} else {
						this.getTagName();
					}
				} else {
					this.searchQuery = null;
					this.currentQuery = null;
				}
			});
	}

	ngOnInit() {
		const lang = this.languageService.currentLang;

		if (lang) {
			if (lang !== CONFIG.defaultLanguage) {
				this.currentLang = lang;
			} else {
				this.currentLang = "";
			}
		}
	}

	getTagName() {
		this.searchService.getTagName(this.searchQuery.query).subscribe(
			response => {
				const key = Object.keys(response)[0];

				if (key && response[key]) {
					this.currentQuery = response[key];
				} else {
					this.searchService.showSearchBlockSubject.next(false);
				}
			},
			err => {
				console.log(err);
			}
		);
	}

	clearSearch() {
		this.searchQuery = null;
		this.currentQuery = null;
		this.searchService.showSearchInputSubject.next(true);
		this.router.navigate(["/" + this.currentLang]);
	}
}
