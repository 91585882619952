import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

/*Services */
import { ApiService } from "./api.service";
import { AppService } from "./app.service";

@Injectable()

export class WorksService implements OnDestroy {
	unsubscribeAll = new Subject();
	isTouchDevice = false;

	public ShowLoadBtnSubject: Subject<any> = new Subject<any>();
	public ShowLoadBtnObservable = this.ShowLoadBtnSubject.asObservable();

	public clickLoadWorksSubject: Subject<any> = new Subject<any>();
	public clickLoadWorksObservable = this.clickLoadWorksSubject.asObservable();

	public playVideoSubject: Subject<any> = new Subject<any>();
	public playVideoObservable = this.playVideoSubject.asObservable();

	public toggleFilterWorkSubject: Subject<any> = new Subject<any>();
	public toggleFilterWorkObservable = this.toggleFilterWorkSubject.asObservable();

	public filterWorksSubject: Subject<any> = new Subject<any>();
	public filterWorksObservable = this.filterWorksSubject.asObservable();

	public filterMenuWorkSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	verticalOffset = 0;

	constructor(
		private apiService: ApiService,
		private appService: AppService
	) {
		this.detectedTouchScreen();

		this.appService.onScrollSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((scroll) => {
				this.verticalOffset = scroll;
			});
	}

	getMastHead(limit?: number, filter?: string): Observable<any> {
		let params = new HttpParams();
		if (limit) {
			params = params.set("limit", limit.toString());
		}
		if (filter) {
			params = params.set("filter", filter.toString());
		}
		return this.apiService.get("/works/musthead", params)
			.pipe(map(data => data));
	}

	getWorks(limit?: number, offset?: number, filter?: string, exclude?: number): Observable<any> {
		let params = new HttpParams();
		let queryParams = "";
		if (limit) {
			params = params.set("limit", limit.toString());
		}
		if (offset) {
			params = params.set("offset", offset.toString());
		}
		if (filter) {
			params = params.set("filter", filter.toString());
		}
		if (exclude) {
			queryParams = "exclude=" + exclude;
		}
		if (queryParams.length) {
			queryParams = "?" + queryParams;
		}
		return this.apiService.get("/works/main" + queryParams, params)
			.pipe(map(data => data));
	}

	getFilters(): Observable<any> {
		return this.apiService.get("/filters")
			.pipe(map(data => data));
	}

	getFilterWorks(limit?: number, offset?: number, filter?: string, exclude?: Array<number>): Observable<any> {
		let params = new HttpParams();
		let queryParams = "";
		if (limit) {
			params = params.set("limit", limit.toString());
		}
		if (offset) {
			params = params.set("offset", offset.toString());
		}
		if (filter) {
			params = params.set("filter", filter.toString());
		}
		if (exclude && exclude.length) {
			queryParams = "exclude=" + exclude.join(",");
		}
		if (queryParams.length) {
			queryParams = "?" + queryParams;
		}
		return this.apiService.get("/works/list" + queryParams, params)
			.pipe(map(data => data));
	}

	getLinks(limit?: number, offset?: number): Observable<any> {
		let params = new HttpParams();
		if (limit) {
			params = params.set("limit", limit.toString());
		}
		if (offset) {
			params = params.set("offset", offset.toString());
		}
		return this.apiService.get("/links/list", params)
			.pipe(map(data => data));
	}

	getWorkDetail(id: number): Observable<any> {
		return this.apiService.get(`/works/${id}`)
			.pipe(map(data => data));
	}

	getSimilarWork(id: number): Observable<any> {
		return this.apiService.get(`/works/similar/${id}`)
			.pipe(map(data => data));
	}

	sortedArray(array, field) {
		return array.sort((a, b) => {
			if (a[field] < b[field]) {
				return 1;
			} else if (a[field] > b[field]) {
				return -1;
			} else {
				return 0;
			}
		});

	}

	setTimeStamp(array, field) {
		array.map(item => {
			if (item[field]) {
				const date = item[field].replace(" ", "T");
				item["timestamp"] = new Date(date).getTime() / 1000;
			}
		});
		return array;
	}

	getYouTubeId(url) {
		let video_id = url.split("v=")[1];
		const ampersandPosition = video_id.indexOf("&");
		if (ampersandPosition !== -1) {
			video_id = video_id.substring(0, ampersandPosition);

		}
		return video_id;
	}

	calcHeight() {
		/*Получием расстояние между блоками */
		const margin = this.appService.getStyle(document.getElementsByClassName("cell")[0], "margin-top");

		const tiles = document.querySelectorAll(".plate-js");
		for (let index = 0; index < tiles.length; index++) {
			const element = tiles[index];

			if (this.appService.isVisible(element)) {
				if (element.classList.contains("plate--block2x2") || element.classList.contains("plate--block1x1")) {
					const height = element.clientWidth;
					element.setAttribute("style", "height:" + height + "px");
				}
				if (element.classList.contains("plate--block1x2")) {
					const height = (element.clientWidth * 2) + margin;
					element.setAttribute("style", "height:" + height + "px");
				}
				if (element.classList.contains("plate--block2x1")) {
					const height = (element.clientWidth - margin) / 2;
					element.setAttribute("style", "height:" + height + "px");
				}

				if (element.classList.contains("plate--block3x2")) {
					const height = (element.clientWidth / 3) * 2;
					element.setAttribute("style", "height:" + height + "px");
				}
			}
		}
	}


	getVimeoId(url) {
		// Look for a string with 'vimeo', then whatever, then a
		// forward slash and a group of digits.
		const match = /vimeo.*\/(\d+)/i.exec(url);

		// If the match isn't null (i.e. it matched)
		if (match) {
			// The grouped/matched digits from the regex
			return match[1];
		}
	}

	addVideoField(data) {
		if (data.video_path.indexOf("youtube") >= 0) {
			data["video_type"] = "youtube";
			data["video_id"] = this.getYouTubeId(data.video_path);
		}

		if (data.video_path.indexOf("vimeo") >= 0) {
			data["video_type"] = "vimeo";
			data["video_id"] = this.getVimeoId(data.video_path);
		}

		return data;
	}

	removeMastheadFromWorks(works, mastHeads) {
		// очищаем список работ от мастхеда
		mastHeads.map(masthead => {
			works.map((work, index) => {
				if (work.id === masthead.id) {
					works.splice(index, 1);
				}
			});
		});
		return works;
	}

	onWindowScroll() {
		if (!this.isTouchDevice) {
			// Только если тачскриновый дисплей
			return;
		}

		let ifScrollToBottom = false;

		// ищем нижнюю границу при скролле, чтоб обнаружить низ
		const scrolled = this.verticalOffset + window.innerHeight;

		// Высота документа
		const paddingHeader = this.appService.getStyle(document.getElementsByClassName("site")[0], "padding-top");

		let documentHeight = document.getElementsByClassName("site-content")[0].clientHeight;
		documentHeight += paddingHeader;

		// уменьшаем на 5% высоту окна, чтоб не ждать пока проскролим в самый низ
		const percentOffset = documentHeight * 0.05;

		if (scrolled > (documentHeight - percentOffset)) {
			// Если проскролили на 95% до низа - считаем что уже внизу и показываем нижние блоки
			ifScrollToBottom = true;
		}

		const tiles = document.querySelectorAll(".plate");

		for (let index = 0; index < tiles.length; index++) {
			const element = tiles[index];
			if (this.appService.isVisible(element)) {
				const offsetTop = element.getBoundingClientRect().top + this.verticalOffset;

				/*Получаем координаты от верха до элемента */
				if (this.verticalOffset === 0 && (offsetTop - 400 - 80) <= 0) {
					/*Отнимаем сдвиг, который используется для анимации, отнимаем высота хедера и находим самый верхний тайл */
					element.classList.add("show-in-touch-device");
					return;
				}

				if (ifScrollToBottom) {
					// Если проскролили на 95% до низа - считаем что уже внизу и показываем нижние блоки
					if (offsetTop > this.verticalOffset) {
						element.classList.add("show-in-touch-device");
					} else {
						element.classList.remove("show-in-touch-device");
					}
				} else {
					if (
						offsetTop > this.verticalOffset &&
						offsetTop < (this.verticalOffset + (window.innerHeight / 4))
					) {
						// находим границу видимости блоков (25% вверху экрана)
						element.classList.add("show-in-touch-device");
					} else {
						element.classList.remove("show-in-touch-device");
					}
				}
			}
		}
	}

	detectedTouchScreen() {
		if (("ontouchstart" in window) || (navigator.msMaxTouchPoints > 0)) {
			this.isTouchDevice = true;
		} else {
			this.isTouchDevice = false;
		}
	}

	getMainImage(imagesArray) {
		/*TODO: временно решение, т.к. нет гарантии что есть обложка, отправил задау на бек */
		let image = imagesArray.filter(item => item.is_main === true)[0];
		if (!image || image.length === 0) {
			image = imagesArray[0].image_path;
		} else {
			image = image.image_path;
		}
		return image;
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}
