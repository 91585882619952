import {Component, OnInit} from '@angular/core';
import {ModalService} from "@services";

@Component({
	selector: 'app-take-test',
	templateUrl: './take-test.component.html',
	styleUrls: ['./take-test.component.scss']
})
export class TakeTestComponent implements OnInit {

	constructor(private modalService: ModalService) {
	}

	ngOnInit() {
	}

	closeModal(id: string) {
		this.modalService.close(id);
	}

	openTest() {
		this.modalService.close('take-test');
		this.modalService.open('career-test');
	}
}
