import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

/* Services */
import { ApiService } from "./api.service";

@Injectable()

export class AboutService {
	constructor(
		private apiService: ApiService
	) { }

	getAboutData(): Observable<any> {
		return this.apiService.get(`/about`).pipe(map(data => data));
	}
}
