import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment } from "@environments";

/*Services */
import { WorksService, SeoService, LanguageService, ShareService, AppService } from "@services";

/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

/*Config */
import { CONFIG } from "@config";

@Component({
	selector: "detail-work",
	templateUrl: "./detail-work.component.html",
	styleUrls: ["./detail-work.component.scss"]
})

export class DetailWorkComponent implements OnInit, OnDestroy {

	workId;
	unsubscribeAll = new Subject();
	workData;
	similarWorkData;
	wrokSlider;
	baseUrl: string = environment.url;
	currentLang;
	similarWorkCountLoaded = 1;
	sliderImages;
	ifSendRequest = false;

	constructor(
		private worksService: WorksService,
		private router: Router,
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private seoService: SeoService,
		private languageService: LanguageService,
		private shareService: ShareService,
		private appService: AppService) {

		this.worksService.toggleFilterWorkSubject.next(false);

		this.router.events
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					this.workId = route.snapshot.params.id;

					if (this.currentLang !== undefined && this.ifSendRequest) {
						this.appService.togglePreloaderSubject.next(true);
						this.getWorkDetail();
						this.getSimilarWork();
					}
				}
			});

		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				if (event.lang !== CONFIG.defaultLanguage) {
					this.currentLang = event.lang;
				} else {
					this.currentLang = "";
				}
				if (!this.ifSendRequest && this.workId) {
					this.getWorkDetail();
					this.getSimilarWork();
				}
			});
		this.appService.onScrollSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((scroll) => {
				this.worksService.onWindowScroll();
			});
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(true);

		const lang = this.languageService.currentLang;
		if (this.currentLang === undefined) {
			if (lang !== CONFIG.defaultLanguage) {
				this.currentLang = lang;
			} else {
				this.currentLang = "";
			}
		}

		if (!this.ifSendRequest && this.workId) {
			this.getWorkDetail();
			this.getSimilarWork();
		}
	}
	initMeta() {
		const title = document.title;
		const description = this.workData.description;
		const url = window.location.href;
		// TODO: добавлять главную обложку а не первую;
		let image;
		if (this.workData.images && this.workData.images.length > 1) {
			image = this.workData.images[0].image_path;
			this.seoService.setOgImage(image);
		}

		this.shareService.updateShare(title, description, url, image);
		this.seoService.setOgUrl(url);
	}

	getWorkDetail() {
		this.sliderImages = null;
		this.ifSendRequest = true;
		this.workData = null;
		this.worksService.getWorkDetail(this.workId).subscribe(
			response => {
				response["type"] = "masthead";
				this.workData = response;
				this.getImages();
				this.seoService.setApiTitle(this.workData.name);
				this.seoService.initMeta();
			},
			err => {
				this.router.navigate(["/" + this.currentLang, "work"]);
				this.appService.togglePreloaderSubject.next(false);
				console.log(err);
			}
		);
	}

	getImages() {
		const sliderImages = [];
		/*если есть обложка и 1 видео, то показываем только видео, проверить это условие после реализации на беке */

		if (this.workData.images && this.workData.images.length > 1 || this.workData.images && this.workData.videos.length === 0) {
			// Показываем только если больше 1-й обложки
			this.sortImages(this.workData.images, "is_main"); // сортируем, чтоб главная была самая первая
			this.workData.images.map(image => {
				sliderImages.push(image);
			});
		}

		if (this.workData.videos && this.workData.videos.length > 0) {
			this.workData.videos.map(video => {
				// добавляем поля для видео
				video = this.worksService.addVideoField(video);

				sliderImages.push(video);
			});
		}

		this.sliderImages = sliderImages;
		let delayTime = 0;
		if (sliderImages.length) {
			delayTime = 300;
		}
		setTimeout(() => {
			/*Показываем не сразу, чтоб картинки успели прогруизится */
			this.appService.togglePreloaderSubject.next(false);
		}, delayTime);

	}

	getSimilarWork() {
		this.similarWorkData = null;
		this.worksService.getSimilarWork(this.workId).subscribe(
			response => {
				if (response && response.length) {
					response.map((item) => {
						item["type"] = "work";
					});
				}

				this.similarWorkData = response;
			},
			err => {
				console.log(err);
			}
		);
	}

	sortImages(array, key) {
		return array.sort(function (a, b) {
			const x = a[key]; const y = b[key];
			return ((x < y) ? 1 : ((x > y) ? -1 : 0));
		});
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}




