import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ModalService, VideoService} from "@services";
import {environment} from "@environments";
import {Subject} from "rxjs";

interface Video {
	id: number;
	name: string | undefined;
	video: string;
	preview_photo: string;
}


@Component({
	selector: 'app-view-video',
	templateUrl: './view-video.component.html',
	styleUrls: ['./view-video.component.scss']
})
export class ViewVideoComponent implements OnInit {
	protected readonly environment = environment;
	@Input() dataStream: Subject<any>;

	@ViewChild('videoPlayer') videoPlayer: ElementRef;
	@ViewChild('progressBar') progressBar: ElementRef;

	videoProgress: number = 0;
	stateVideo = false;
	data: Video;

	constructor(private modalService: ModalService,
				private videoService: VideoService) {
	}

	ngOnInit() {
		this.videoService.dataStream$.subscribe(data => {
			this.data = data;
		});
	}

	closeModal(id: string) {
		this.resetModal();
		this.modalService.close(id);
	}

	updateProgress() {
		const currentTime = this.videoPlayer.nativeElement.currentTime;
		const duration = this.videoPlayer.nativeElement.duration;
		this.videoProgress = (currentTime / duration) * 100;
	}

	startVideo() {
		this.stateVideo = !this.stateVideo;
		if (this.videoPlayer) {
			this.stateVideo ? this.videoPlayer.nativeElement.play() : this.videoPlayer.nativeElement.pause();
		}
	}

	resetModal() {
		this.data = null;
		this.videoProgress = 0;
		this.stateVideo = false;
		if (this.videoPlayer) {
			this.videoPlayer.nativeElement.pause();
		}
	}

	onVideoLoaded() {
		if (this.videoPlayer.nativeElement.readyState >= 1) {
			this.stateVideo = true;
			this.videoPlayer.nativeElement.play();
		}
	}
}
