export * from "./home/home.component";
export * from "./work/all-works/all-works.component";
export * from "./work/detail-work/detail-work.component";
export * from "./clients/clients.component";
export * from "./people/all-people/all-people.component";
export * from "./people/detail-people/detail-people.component";
export * from "./about/about.component";
export * from "./contact/contact.component";
export * from "./search/search.component";
export * from "./page-not-found/page-not-found.component";
export * from "./education/education.component";
export * from "./education/course-pages/creative-course/creative-course.component";
