import { Component, OnDestroy, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
/*Services */
import { WorksService } from "@services";
/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
/*Config */
import { CONFIG } from "@config";
import { IFilterMenu } from "../interfaces";

@Component({
	selector: "filter-works-list",
	templateUrl: "./filter-works-list.component.html"
})
export class FilterWorksListComponent implements AfterViewInit, OnDestroy {
	currentFilter: string; // Текущий фильтр для работ
	currentLang = "";
	unsubscribeAll = new Subject();
	filterMenu: IFilterMenu[] = [];
	@Output() clickItemMenu: EventEmitter<any> = new EventEmitter();

	constructor(
		private worksService: WorksService,
		private router: Router,
		private translateService: TranslateService
	) {

		this.router.events
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					if (location.search.indexOf("filter")) {
						// Получаем текущий фильтр из url
						const param = location.search.replace("?filter=", "");
						if (param) {
							this.currentFilter = param;
						} else {
							this.currentFilter = "new";
						}
					}
				}
			});

		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				if (event.lang !== CONFIG.defaultLanguage) {
					this.currentLang = event.lang;
				} else {
					this.currentLang = "";
				}
			});
	}

	filterWorks(filter) {
		this.clickItemMenu.emit(true);

		if (this.currentFilter !== filter) {
			this.currentFilter = filter;
			this.worksService.filterWorksSubject.next(this.currentFilter);
		}
	}

	ngAfterViewInit() {
		this.worksService.filterMenuWorkSubject.subscribe((filterItems) => {
			if (filterItems) {
				this.filterMenu = filterItems;
			}
		});
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
		this.worksService.filterWorksSubject.next(false);
	}
}
