import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router, NavigationEnd } from "@angular/router";

/*Services */
import { WorksService, ClientsService, PeopleService } from "@services";
@Component({
	selector: "footer-app",
	templateUrl: "./footer.component.html",
	styleUrls: ["footer.component.scss"],
})
export class FooterComponent implements OnInit, OnDestroy {

	ifShowLoad = false;
	typeDataLoad: string;
	unsubscribeAll = new Subject();

	constructor(
		private worksService: WorksService,
		private clientsService: ClientsService,
		private router: Router,
		private peopleService: PeopleService) {
	}

	ngOnInit() {
		this.router.events
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					this.ifShowLoad = false;
					this.typeDataLoad = null;
				}
			});

		this.worksService.ShowLoadBtnObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(value => {
				if (value) {
					this.typeDataLoad = value;
					this.ifShowLoad = true;
				} else {
					this.ifShowLoad = false;
				}
			});
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}

	loadData() {
		if (this.typeDataLoad === "work") {
			this.worksService.clickLoadWorksSubject.next();
		}
		if (this.typeDataLoad === "clients") {
			this.clientsService.clickLoadClientsSubject.next();
		}
		if (this.typeDataLoad === "workPerson") {
			this.peopleService.clickLoadWorksPersonSubject.next();
		}
	}
}
