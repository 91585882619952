import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";

import { CompleterData } from "ng2-completer";

/* Environment */
import { environment } from "@environments";

import { TranslateService } from "@ngx-translate/core";
import { ISearchResultItem, ISearchResults } from "app/core/models/search-result.model";
import { ICustomCompleterItem } from "app/core/models/custom-compiler-item.model";

// Custom data for ng2-completer

export class SearchCustomData extends Subject<ICustomCompleterItem[]> implements CompleterData {
    constructor(
		private http: HttpClient,
		private translateService: TranslateService,
	) {
        super();
    }

    public search(query: string): void {
		this.http.get(`${environment.api_url}/search/autocomplete?query=${query}`)
            .pipe(
                map((data: ISearchResults) => {
					const categories = Object.keys(data);
					let matches: ICustomCompleterItem[] = [];
					let worksAdded = false;

					categories.forEach((category) => {
						const hasWorks = category.toLowerCase() === "works" || category.toLowerCase() === "messages";
						let items: ICustomCompleterItem[];

						if (!data[category] 
							|| Array.isArray(data[category]) && data[category].length < 1 
							|| worksAdded && hasWorks) {
							return;
						}

						if (hasWorks) {
							items = [
									{
									title: `${this.translateService.instant('SEARCH.TO_SEARCH')} "${query}" ${this.translateService.instant('SEARCH.IN_WORKS')}`,
									originalObject: {
										name: query,
										slug: query,
										id: 0,
									},
									category: "works",
								},
							];

							worksAdded = true;
						} else {
							items = data[category]
								.slice(0, 3)
								.map((item: ISearchResultItem): ICustomCompleterItem => {
									let title: string;

									switch (category) {
										case "bounty": title = `${item.name} (${this.translateService.instant('SEARCH.CATEGORY_BOUNTY')})`; break;
										case "brand": title = `${item.name} (${this.translateService.instant('SEARCH.CATEGORY_BRAND')})`; break;
										case "campaign_type": title = item.name; break;
										case "people": title = item.name; break;
										default: {
											title = item.name;
										}
									}

									return {
										title,
										originalObject: {
											...item,
										},
										category,
									};
							});
						}

						matches = [
							...matches,
							...items,
						];
					});

                    this.next(matches);
                })
            )
            .subscribe();
    }

    public cancel() {
        // Handle cancel
    }
}
