import {Component, OnInit} from '@angular/core';
import {AppService, EducationService} from "@services";
import {environment} from "@environments";
import Swiper from 'swiper';

interface Card {
	content: object[];
	icon: string;
	text: string;
}


@Component({
	selector: 'app-creative-course',
	templateUrl: './creative-course.component.html'
})
export class CreativeCourseComponent implements OnInit {

	protected readonly environment = environment;
	curatorsList;
	internshipsDirections;
	idCourse = 1;
	data;
	tabs;
	selectedTab: Card

	constructor(private api: EducationService,
				private appService: AppService) {
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(true);
		this.api.curators(this.idCourse).subscribe((response) => {
			this.curatorsList = response;
			if (this.curatorsList) {
				setTimeout(() => {
					this.initSlider();
					this.appService.togglePreloaderSubject.next(false);
				})
			}
		});

		this.api.internshipsDirections(this.idCourse).subscribe((response) => {
			this.internshipsDirections = response;
			this.data = response;
		});

		this.api.creativeData().subscribe((response) => {
			this.tabs = response;
			this.selectedTab = this.tabs[0];
		});
	}

	initSlider() {
		new Swiper(".swiper-container", {
			loop: false,
			speed: 800,
			slidesPerView: 4,
			centeredSlides: false,
			shadow: false,
			grabCursor: true,
			parallax: true
		});
	}

	selectTab(tab) {
		this.selectedTab = tab;
	}
}
