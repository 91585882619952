import { Injectable, OnDestroy } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

/*Config */
import { CONFIG } from "@config";

@Injectable()
export class HttpLanguageInterceptor implements HttpInterceptor, OnDestroy {

	unsubscribeAll = new Subject();
	currentLang;

	constructor(
		private translateService: TranslateService,
		private router: Router,
		private route: ActivatedRoute) {
		this.router.events
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					const urlLang = route.snapshot.children[0].params["lang"];

					if (urlLang) {
						if (CONFIG.languages.indexOf(urlLang) < 0) {
							return;
						}
						if (urlLang !== this.currentLang) {
							this.currentLang = urlLang;
						}
					} else {
						if (this.currentLang !== CONFIG.defaultLanguage) {
							this.currentLang = CONFIG.defaultLanguage;
						}
					}
				}
			});

		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				this.currentLang = this.translateService.currentLang; // en or de
			});
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const headersConfig = {};
		if (this.currentLang) {
			headersConfig["Accept-Language"] = this.currentLang;
		} else {
			headersConfig["Accept-Language"] = CONFIG.defaultLanguage;
		}
		const request = req.clone({ setHeaders: headersConfig });

		return next.handle(request);
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}
