import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
/*Services */
import { ApiService } from "./api.service";

@Injectable()

export class ClientsService {
	public clickLoadClientsSubject: Subject<any> = new Subject<any>();
	public clickLoadClientsObservable = this.clickLoadClientsSubject.asObservable();

	public toggleFilterClientsSubject: Subject<any> = new Subject<any>();
	public toggleFilterClientsObservable = this.toggleFilterClientsSubject.asObservable();

	public filterClientsSubject: Subject<any> = new Subject<any>();
	public filterClientsObservable = this.filterClientsSubject.asObservable();

	constructor(
		private apiService: ApiService
	) { }

	getClients(limit?: number, offset?: number, filter?: string): Observable<any> {
		let params = new HttpParams();
		let queryParams = "";
		if (limit) {
			params = params.set("limit", limit.toString());
		}
		if (offset) {
			params = params.set("offset", offset.toString());
		}
		if (filter) {
			params = params.set("filter", filter.toString());
		}
		if (queryParams.length) {
			queryParams = "?" + queryParams;
		}
		return this.apiService.get("/brands/list" + queryParams, params)
			.pipe(map(data => data));
	}

	getFilterClients(limit?: number, offset?: number, filter?: string): Observable<any> {
		let params = new HttpParams();
		let queryParams = "";
		if (limit) {
			params = params.set("limit", limit.toString());
		}
		if (offset) {
			params = params.set("offset", offset.toString());
		}
		if (filter) {
			params = params.set("filter", filter.toString());
		}
		if (queryParams.length) {
			queryParams = "?" + queryParams;
		}
		return this.apiService.get("/brands/list" + queryParams, params)
			.pipe(map(data => data));
	}
}
