import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpLanguageInterceptor } from "./interceptors/http.language.interceptor";

import {
	ApiService,
	WorksService,
	LanguageService,
	AppService,
	ClientsService,
	PeopleService,
	SearchService,
	SeoService,
	ShareService,
	AboutService,
	ModalService, EducationService, VideoService
} from "./services";

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: HttpLanguageInterceptor, multi: true },
		ApiService,
		WorksService,
		LanguageService,
		AppService,
		ClientsService,
		PeopleService,
		SearchService,
		SeoService,
		ShareService,
		AboutService,
		ModalService,
		EducationService,
		VideoService
	],
	declarations: []
})

export class CoreModule { }
