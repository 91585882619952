import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';

import {ModalService} from "@services";

@Component({
	selector: 'modal',
	template: '<ng-content></ng-content>'
})

export class ModalComponent implements OnInit, OnDestroy {
	@Input() id: string;
	private element: any;

	constructor(private modalService: ModalService, private el: ElementRef) {
		this.element = el.nativeElement;
	}

	ngOnInit(): void {
		let modal = this;

		if (!this.id) {
			return;
		}

		document.body.appendChild(this.element);
		this.element.addEventListener('click', function (e: any) {
			if (e.target.className === 'modal') {
				modal.close();
			}
		});
		this.modalService.add(this);
	}

	ngOnDestroy(): void {
		this.modalService.remove(this.id);
		this.element.remove();
	}

	open(): void {
		this.element.classList.add('active');
		document.body.classList.add('modal-open');
	}

	close(): void {
		this.element.classList.remove('active');
		document.body.classList.remove('modal-open');
	}
}
