import {
	Component,
	ViewEncapsulation,
	OnInit,
	OnDestroy,
	AfterContentChecked,
	AfterContentInit,
	AfterViewChecked,
	DoCheck
} from "@angular/core";
import { Subject, from } from "rxjs";
import { takeUntil } from "rxjs/operators";

/*Eenvironment */
import { environment } from "@environments";

/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

/*Services */
import { ClientsService, WorksService, AppService, LanguageService, ShareService, SeoService } from "@services";

/*Config */
import { CONFIG } from "@config";

/*Static */
// import { clientsStatic } from "./clients.static";

@Component({
	selector: "clients",
	templateUrl: "./clients.component.html",
	styleUrls: ["./clients.component.scss"],
	encapsulation: ViewEncapsulation.None
})

export class ClientsComponent implements OnInit, OnDestroy {

	unsubscribeAll = new Subject();
	baseUrlImage = environment.url;
	ifLoadingGetClients = false;
	clientsCountloaded = 0;
	clientsLeftover = 0;
	clientsData = [];
	currentLang: string;
	currentLangUrl: string;
	currentFilter: string;
	// reviewsData;

	slideConfig = {
		"slidesToShow": 1,
		"slidesToScroll": 1,
		"arrows": false,
		"dots": true
	};

	constructor(
		private translateService: TranslateService,
		private clientsService: ClientsService,
		private worksService: WorksService,
		private appService: AppService,
		private languageService: LanguageService,
		private shareService: ShareService,
		private seoService: SeoService
	) {

		if (location.search.indexOf("filter")) {
			// Получаем текущий фильтр из url
			const param = location.search.replace("?filter=", "");
			if (param) {
				this.currentFilter = param;
			} else {
				this.currentFilter = "new";
			}
		}

		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				this.currentLang = event.lang;

				if (event.lang !== CONFIG.defaultLanguage) {
					this.currentLangUrl = event.lang;
				} else {
					this.currentLangUrl = "";
				}

				// this.getReviews();
				this.getClients();
			});

		this.clientsService.clickLoadClientsObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(() => {
				/*Кликнули в футере загрузить еще */
				this.getMoreClients();
			});

		this.clientsService.filterClientsObservable
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(filter => {
				// Изменили фильтр
				this.currentFilter = filter;

				if (this.currentFilter) {
					this.clientsCountloaded = 0;
					this.clientsLeftover = null;
					this.clientsData = [];
					this.getClients();
					this.appService.togglePreloaderSubject.next(true);
				}
			});
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(true);
		this.clientsService.toggleFilterClientsSubject.next(true);

		const lang = this.languageService.currentLang;

		if (this.currentLang === undefined && lang) {
			this.currentLang = lang;

			if (lang !== CONFIG.defaultLanguage) {
				this.currentLangUrl = lang;
			} else {
				this.currentLangUrl = "";
			}

			// this.getReviews();
			this.getClients();
			// this.toggleLoadBtn();
		}

		// Обработка скролла для Lazy load
		this.appService.onScrollSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((topOffset) => {
				const heightPage = this.getHeightDocumentAndWindow();

				const dividend = (heightPage.docHeight ===  heightPage.windowHeight) ?
					1 : (topOffset / (heightPage.docHeight - heightPage.windowHeight));

				if (dividend > 0.9) {
					this.getMoreClients();
				}
			});
	}

	getHeightDocumentAndWindow() {
		const body = document.body;
		const html = document.documentElement;
		const docHeight = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.clientHeight,
			html.scrollHeight,
			html.offsetHeight
		);

		return {
			docHeight: docHeight,
			windowHeight: html.clientHeight
		};
	}

	/* getReviews() {
		this.reviewsData = clientsStatic.reviews;
	} */

	getClients() {
		if (this.ifLoadingGetClients) {
			return;
		}

		this.ifLoadingGetClients = true;
		this.clientsData = null;

		this.clientsService.getFilterClients(CONFIG.countClients, null, this.currentFilter).subscribe(
			response => {
				this.ifLoadingGetClients = false;
				if (response && response.items && response.items.length) {
					this.clientsData = response.items;
					this.clientsCountloaded += response.items.length;
					this.clientsLeftover = +response.leftover;

					this.appService.animateShowSubject.next("true");

					this.appService.togglePreloaderSubject.next(false);

					const heightPage = this.getHeightDocumentAndWindow();

					if (!!this.clientsLeftover && heightPage.docHeight === heightPage.windowHeight) {
						this.getMoreClients();
					}

					// this.toggleLoadBtn();

					setTimeout(() => {
						// Grayscale для изображений в IE10-IE11
						this.grayScale();
					});
				} else {
					this.clientsData = [];
				}
			},
			err => {
				this.appService.togglePreloaderSubject.next(false);
				this.ifLoadingGetClients = false;
				console.log(err);
			}
		);
	}

	getMoreClients() {
		if (this.ifLoadingGetClients || !this.clientsLeftover) {
			return;
		}

		this.ifLoadingGetClients = true;

		this.clientsService.getFilterClients(CONFIG.countClients, this.clientsCountloaded, this.currentFilter).subscribe(
			response => {
				this.ifLoadingGetClients = false;

				if (response && response.items && response.items.length) {
					this.clientsData = this.clientsData.concat(response.items);
					this.clientsCountloaded += response.items.length;
					this.clientsLeftover = +response.leftover;
					this.appService.animateShowSubject.next("true");
				}

				// this.toggleLoadBtn();

				setTimeout(() => {
					// Grayscale для изображений в IE10-IE11
					this.grayScale();
				});
			},
			err => {
				this.ifLoadingGetClients = false;
				console.log(err);
			}
		);
	}

	grayScale() {
		// Grayscale для изображений в IE10-IE11
		const needToFix = /(MSIE 10)|(Trident.*rv:11\.0)|( Edge\/[\d\.]+$)/.test(navigator.userAgent);
		if (needToFix) {
			const images = document.querySelectorAll("img.grayscale");
			for (let i = 0; i < images.length; i++) {
				this.replaceImage(images[i]);
			}
		}
	}

	replaceImage(image) {
		const tmpImage = new Image();
		tmpImage.onload = function () {
			const imgWrapper = document.createElement("span"),
				svgTemplate =
					"<svg xmlns=\"http://www.w3.org/2000/svg\" id=\"svgroot\" viewBox=\"0 0 " + tmpImage.width + " " + tmpImage.height +
					"\" width=\"100%\" height=\"100%\">" +
					"<defs>" +
					"<filter id=\"gray\">" +
					"<feColorMatrix type=\"matrix\" values=\"0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\" />" +
					"</filter>" +
					"</defs>" +
					"<image filter=\"url(&quot;#gray&quot;)\" x=\"0\" y=\"0\" width=\"" + tmpImage.width + "\" height=\"" + tmpImage.height +
					"\" preserveAspectRatio=\"none\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"" + tmpImage.src + "\" />" +
					"</svg>";

			imgWrapper.innerHTML = svgTemplate;
			imgWrapper.className = "grayscale-fix";
			image.parentNode.insertBefore(imgWrapper, image);

			image.style.cssText += "visibility:hidden;display:block";
			imgWrapper.querySelector("svg").style.position = "absolute";
			imgWrapper.appendChild(image);
		};
		tmpImage.src = image.src;
	}

	sortArrayDown(array) {
		return array.sort((obj1, obj2) => {
			if (obj1.deviceTime > obj2.deviceTime) {
				return -1;
			}

			if (obj1.deviceTime < obj2.deviceTime) {
				return 1;
			}

			return 0;
		});
	}

	toggleLoadBtn() {
		// Если в базе больше нет работ и сообщений скрываем кнопку
		if (typeof this.clientsLeftover === 'number' && this.clientsLeftover < 1) {
			this.worksService.ShowLoadBtnSubject.next(false);
		} else {
			this.worksService.ShowLoadBtnSubject.next("clients");
		}
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}



