import { Component, OnDestroy, ViewEncapsulation, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/*Services */
import { AppService } from "@services";

/*Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

/*Config */
import { CONFIG } from "@config";

@Component({
	selector: "page-not-found",
	templateUrl: "./page-not-found.component.html",
	styleUrls: ["./page-not-found.component.scss"],
	encapsulation: ViewEncapsulation.None
})

export class PageNotFoundComponent implements OnInit, OnDestroy {

	currentLang = "";
	unsubscribeAll = new Subject();

	constructor(
		private translateService: TranslateService,
		private appService: AppService) {
		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				if (event.lang !== CONFIG.defaultLanguage) {
					this.currentLang = event.lang;
				} else {
					this.currentLang = "";
				}
			});
	}

	ngOnInit() {
		this.appService.togglePreloaderSubject.next(false);
	}

	ngOnDestroy() {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}


