import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

/* Services */
import { ApiService } from "./api.service";

import { IQuerySearch } from "../models/search-query.model";

@Injectable()

export class SearchService {

	public showSearchBlockSubject: Subject<any> = new Subject<any>();
	public showSearchBlockObservable = this.showSearchBlockSubject.asObservable();

	public showSearchInputSubject: Subject<any> = new Subject<any>();
	public showSearchInputObservable = this.showSearchInputSubject.asObservable();

	constructor(
		private apiService: ApiService
	) { }

	search(querySearch: IQuerySearch): Observable<any> {
		const formData: FormData = new FormData();

		formData.append("query", querySearch.query);
		formData.append("category", querySearch.category);

		return this.apiService
			.post("/search/qwery", formData)
			.pipe(map(data => data));
	}

	getTagName(query: string): Observable<any> {
		return this.apiService
			.get(`/search/${query}`)
			.pipe(map(data => data));
	}
}
