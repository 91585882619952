import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class AppService {
	public onScrollSubject: Subject<any> = new Subject<any>();
	public onScrollObservable = this.onScrollSubject.asObservable();

	public animateShowSubject: Subject<any> = new Subject<any>();
	public animateShowObservable = this.animateShowSubject.asObservable();

	public togglePreloaderSubject: Subject<any> = new Subject<any>();
	public togglePreloaderObservable = this.togglePreloaderSubject.asObservable();

	sliceWorksArray(countArray, works, lastType?) {
		/*	Метод нарезает список работ
			по указанным кол-вам,
			т.к. выводить нужно определенное кол-во
			работ в строке для каждого типа экрана
		*/

		// Временная переменная для хранения
		const array = [];

		// Текущий порядок шаблона
		let currentCount = 0;

		if (lastType) {
			// Если добавялем работы к уже созданным - нужно продолжить с последнего шаблона, т.к. есть определенный порядок
			currentCount = lastType;
		}

		// Экранируем массив */
		const tempArrayWorks = JSON.parse(JSON.stringify(works));
		// Нарезаем массив пока не закончится */
		while (tempArrayWorks.length > 0) {
			if ((currentCount + 1) > countArray.length) {
				// Если мы на последнем шаблоне, переходим на первый
				currentCount = 0;
			}
			array.push({ type: currentCount, data: tempArrayWorks.splice(0, countArray[currentCount]) });

			// Переходим к следующем шаблону
			currentCount++;
		}
		return array;
	}

	getStyle(element: Element, style) {
		if (element) {
			/*Получаем стили для эелемента */
			const styles = window.getComputedStyle(element);
			return parseInt(styles.getPropertyValue(style));
		} else {
			return 0;
		}
	}

	isVisible(element) {
		/*Проверяем на видимость элемента */
		if (element.clientWidth !== 0 && element.clientWidth !== 0) {
			return true;
		} else {
			return false;
		}
	}

	addDataType(type: string, data: object[]) {
		if (!type || !data) {
			return;
		}

		return data.map((item) => {
			return {
				...item,
				type,
			};
		});
	}
}
