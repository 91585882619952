import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/* Services */
import { SearchService } from "@services";

/* Services */
import { LanguageService, WorksService, AppService } from "@services";

/* Plugins */
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

/* Config */
import { CONFIG } from "@config";

import { IQuerySearch } from "app/core/models/search-query.model";

@Component({
	selector: "search",
	templateUrl: "./search.component.html"
})

export class SearchComponent implements OnInit, OnDestroy {
	unsubscribeAll = new Subject();
	currentLang: string;
	searchQuery: IQuerySearch;
	searchResult;
	ifLoading = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private searchService: SearchService,
		private translateService: TranslateService,
		private languageService: LanguageService,
		private worksService: WorksService,
		private appService: AppService
	) {
		this.translateService.onLangChange
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe((event: LangChangeEvent) => {
				if (event.lang !== CONFIG.defaultLanguage) {
					this.currentLang = event.lang;
				} else {
					this.currentLang = "";
				}
			});

		this.router.events
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					if (route.snapshot.params.query && route.snapshot.params.query.length > 0) {
						this.searchQuery = {
							query: route.snapshot.params.query,
							category: route.snapshot.params.category,
						};
						this.searchService.showSearchBlockSubject.next(this.searchQuery);

						if (this.searchQuery.category === "works") {
							this.search();
						} else {
							this.getTagName();
						}
					} else {
						this.appService.togglePreloaderSubject.next(false);
						this.searchService.showSearchBlockSubject.next(false);
					}
				}
			});

		this.appService.onScrollSubject
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(() => {
				this.worksService.onWindowScroll();
			});
	}

	ngOnInit() {
		const lang = this.languageService.currentLang;

		if (lang) {
			if (lang !== CONFIG.defaultLanguage) {
				this.currentLang = lang;
			} else {
				this.currentLang = "";
			}
		}
	}

	getTagName() {
		this.searchService.getTagName(this.searchQuery.query).subscribe(
			response => {
				const key = Object.keys(response)[0];

				if (key && response[key]) {
					this.searchQuery.query = response[key];
				}

				this.search();
			},
			err => {
				console.log(err);
			}
		);
	}

	search() {
		this.appService.togglePreloaderSubject.next(true);
		this.ifLoading = true;

		this.searchService
			.search(this.searchQuery)
			.subscribe(
				response => {
					if (response) {
						// Тип message нужно конвертировать в link (тип плитки)
						// А всему остальному сделать тип works для корректного отображения
						response.forEach((item) => {
							item["type"] = (item["type"] === "message") ? "link" : "work";
						});

						this.searchResult = response;
					} else {
						this.searchResult = [];
					}

					this.ifLoading = false;
					this.appService.togglePreloaderSubject.next(false);
				},
				err => {
					this.appService.togglePreloaderSubject.next(false);
					this.ifLoading = false;
					console.log(err);
				}
			);
	}

	ngOnDestroy() {
		this.searchService.showSearchBlockSubject.next(false);
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}




